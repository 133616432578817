@import "../../../markup";

.toaster {
  border-radius: $border-radius-xmedium;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;;
  animation: 1s ease-out 0s 1 slideInFromRight;

  .toaster__icon {
    width: 1.5rem;
    height: 1.4rem;
  }

  .toaster__message {
    color: $mid-purple;
    font-family: $main-font-medium;
    font-size: 0.8rem;
  }
}

.toaster--warning {
  background-color: $light-yellow;
}

.toaster--success {
  background-color: $light-green;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0);
  }
}
