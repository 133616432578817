.container-appliicant-provider {
  &.container {
    margin-top: 0;
    box-sizing: border-box;
  }
  * {
    box-sizing: border-box;
  }
  .show-icon svg {
    display: inline-block !important;
  }
  .home-container,.auditor_update_personal_info-auditorList-container{
    min-width: unset;
    position: relative;
    padding-top: 40px;
  }
  .auditorList,.auditor_update_personal_info-auditorList,.bg-ApplicantForm {
    max-width: unset;
    width: 100%;
    margin-top: 0;
    min-height: unset;
    @media (max-width: 565px) {
      .orderes-header {
        flex-direction: column-reverse;
      }
    }
  }
  .auditor_personal_info-div {
    margin-bottom: 20px;
}
.bg-ApplicantForm {
  position: relative;
  min-height: unset;
  inset: unset;
  min-width: unset;
  padding-bottom: 50px !important;
}
.col-step-service {
  height: 100%;
  margin: 0;
  padding: 35px;
  width: 25%;
}
.header-link {
  margin: 0;
}

.steps-content-service {
  position: relative;
  inset: unset;
  width: 70%;
  @media (max-width: 782px) {
    width: 100%;
  }
}
.ant-btn-default:active {
  color: #513b71;
  border-color: #513b71;
}
.ant-btn-default:hover {
  color: #513b71;
  border-color: #513b71;
}
}


.ant-dropdown {
  font-size: 32px;
  font-family: 'DIN Med' !important;
  color: #513b71;
};
.ant-dropdown-menu-item {
  white-space: nowrap;
}