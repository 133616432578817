.options-edu{
    direction: ltr;
}



.inputTextEd{
  text-indent: 8px;
    width: 100%;
    background-color: 'white';
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    color: #584f70;
    border: 1px solid #50456698;
    margin-top: 10px;
    outline: none;
    &:disabled{
      -webkit-text-fill-color: #584f70;
      background-color: #EFEFEF;
    }  


    @media only screen and (min-width: 361px) and (max-width: 427px) {
        width: 100%;

      }
}  