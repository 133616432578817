@import "../../../markup";

.datepicker-header{
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-direction: row;
}
.month-select{
margin-left: 120px;


    &__input{
        position:absolute;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        width: 100px;
        max-height:200px;
        overflow:overlay;    
        font-size: 14px;
        font-weight: 500;
        font-family: $main-font-regular;
        color: #584f70;
        border: 1px solid #50456698;
        border-radius: $border-radius-small;
        margin-top: 10px;
        outline: none;
        background: url(../../../assets/images/shared/arrow-down.svg) no-repeat 8px 12px #fff ;

    }
    ul li.option {
    
        z-index: 1;
        padding-top: 5px;
        padding-bottom: 5px;
        list-style: none;

    
        &:hover{
            background-color:  #DDE8E3;
        }
    }
    ul.closed li.option {
        display: none;
        
    }
    ul.closed li:first-child {
        background: url(../../../assets/images/shared/arrow-down.svg) no-repeat 8px 12px #fff ;
        display: block;
    }
}
.year-select{
    margin-right: 20px;
    &__input{

        position:absolute;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        width: 100px;
        // height: 30px;
        max-height:200px;
        overflow:overlay;     
        font-size: 14px;
        font-weight: 500;
        font-family: $main-font-regular;
        color: #584f70;
        border: 1px solid #50456698;
        border-radius: $border-radius-small;
        margin-top: 10px;
        outline: none;
        background: url(../../../assets/images/shared/arrow-down.svg) no-repeat 8px 12px #fff ;
    

    }
    ul li.option {
    
        z-index: 1;
        padding-top: 5px;
        padding-bottom: 5px;
        list-style: none;
        display: none;
    
        &:hover{
            background-color:  #DDE8E3;
        }
    }
    ul.closed li.option {
        display: none;
    }
    ul.closed li:first-child {
        background: url(../../../assets/images/shared/arrow-down.svg) no-repeat 8px 12px #fff ;

        display: block;
    }
}
.date-input {
    direction: ltr;
    text-align: end;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #584f70;
    border: 1px solid #50456698;
    border-radius: $border-radius-small;
    width: 300px;
    height: 40px;
    margin-top: 10px;
    outline: none;
    background: url(../../../assets/images/shared/arrow-down.svg) no-repeat 10px 15px #5045660d;
  
  
  }
  



.day_name{
    display: inline-block;
    color:#404040;
    margin: 2px;
    width: 30px;
    height: 25px;
    padding: 1px;
    border: 1px solid #fff;
    text-align: center;
    -webkit-box-sizing: unset !important;
    font-family: $main-font-regular;
    box-sizing: unset;
}


