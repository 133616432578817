@import "../../../markup";

.password__error {
  color: #6c6c6c;
  display: block;
  font-size: 1rem;
  font-family: $main-font-regular;
  &-check {
    padding-right: 31px;
  }
}