@import '../../../../../markup';

.services-tit {
  color: #513b71;
  font-family: DIN Med;
  margin-bottom: 15px;
  font-size: 20px;
}

.services-sub-tit {
  color: #6c6c6c;
  font-family: DIN Regular;
  margin-bottom: 15px;
  font-size: 16px;
}

.main {
  background-color: #f6f5f7;
  border: 1px solid #dedce2;
  border-radius: 1.5rem;
  height: 460px;
  position: relative;
  overflow: hidden;
  padding-right: 2rem;
  padding-bottom: 20px;
  padding-top: 20px;

  @media screen and (max-width: 1400px) {
    padding-right: 0.5rem;
  }
}
.services-card {
  grid-gap: 14px;
  background-color: #f6f5f7;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;

  @media screen and (max-width: 1400px) {
    display: block;
  }
}
.main ::-webkit-scrollbar {
  width: 8px;
}
.main ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.main ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.main ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.services-sm-card {
  width: 67px;
  height: 64px;
  background: #584f703b 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.services-checkbox {
  margin-top: 9px;
  margin-right: 8px;
}
// input[type=checkbox] {
//   accent-color: #513B71;
// }

.services-1 {
  position: relative;
}
.text-serv-1 {
  font-family: DIN Regular;
  color: #513b71;
}
.text-serv-2 {
  font-family: DIN Regular;
  color: #6c6c6c;
  font-size: 14px;
}
.services-img-1 {
  position: absolute;
  right: 15px;
  top: 26px;
}
.services-img-2 {
  position: absolute;
  right: 18px;
  top: 31px;
}

.services-img-3 {
  position: absolute;
  right: 17px;
  top: 30px;
}
.services-img-4 {
  position: absolute;
  right: 18px;
  top: 30px;
}
.services-img-5 {
  position: absolute;
  right: 16px;
  top: 27px;
}
.services-img-6 {
  position: absolute;
  right: 17px;
  top: 28px;
}
.services-img-7 {
  position: absolute;
  right: 17px;
  top: 28px;
}
.services-img-8 {
  position: absolute;
  right: 16px;
  top: 27px;
}
.services-img-9 {
  position: absolute;
  right: 17px;
  top: 29px;
}
.services-img-10 {
  position: absolute;
  right: 17px;
  top: 29px;
}
.services-img-11 {
  position: absolute;
  right: 19px;
  top: 28px;
}
.services-img-12 {
  position: absolute;
  right: 20px;
  top: 31px;
}
.services-img-13 {
  position: absolute;
  right: 18px;
  top: 31px;
}
.services-img-14 {
  position: absolute;
  right: 18.5px;
  top: 31px;
}
.services-img-15 {
  position: absolute;
  right: 18.5px;
  top: 30px;
}

// #container1{
//     height: 100%;
//     width: 100%;
//     overflow: hidden;
// }

//  #container2{
//     height: 100%;
//     width: 100%;
//     overflow: auto;
//     padding-right: 20px;
// }

// CUSTOM CHECKBOX

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 6px;
  right: 6px;
  height: 14px;
  width: 15px;
  background-color: #fff;
  border: 1px black solid;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #513b71;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}

.invalid_checkbox {
  color: #b41515;
  font-family: DIN Regular;
  margin-bottom: 15px;
  font-size: 16px;
}

.main-comment {
  color: $purple;
  font-size: 18px;
  padding-bottom: 1rem;
}

.sub-comment {
  font-size: 16px;
}
