.flex {
  display: flex;
}

.flex--center {
  justify-content: center;
}

.background-full__screen {
  background-image: url('../../assets/images/shared/login-bg2.svg');
  background-size: auto;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  background-position: top;
}
button:disabled {
  cursor: not-allowed;
}
