@import '../../../markup';

.input {
  padding-top: 2rem;

  @media screen and (max-width: 1023px) {
    padding-top: 1rem;
  }

  &__label {
    color: #6c6c6c;
    display: block;
    font-size: 1.1rem;
    font-family: $main-font-regular;
    &-personalInfo {
      @extend .input__label;
      font-size: 16px;
    }
  }

  &__text {
    direction: ltr;
    text-align: end;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #584f70;
    border: 1px solid #50456698;
    border-radius: $border-radius-small;
    width: 300px;
    height: 40px;
    background-color: #5045660d;
    margin-top: 10px;
    outline: none;
    font-family: 'DIN Regular';
    &:disabled {
      -webkit-text-fill-color: #584f70;
      background-color: #efefef;
    }
    @media screen and (max-width: $phone-Small) {
      width: 240px;
      height: 35px;
    }
    @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
      width: 300px;
      height: 36px;
    }

    &::placeholder {
      color: #c7c7c7;
      font-size: 18px;
      font-family: $main-font-regular;
      padding-right: 8px;
      padding-bottom: 8px;
    }
  }
}

.input__field {
  position: relative;
}

.input__password-icon {
  cursor: pointer;
  position: absolute;
  left: 13px;
  top: 35%;
}

.date-input {
  direction: ltr;
  text-align: end;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #513b71;
  border: 1px solid #50456698;
  border-radius: $border-radius-small;
  width: 300px;
  height: 40px;
  margin-top: 10px;
  outline: none;
  background: url(../../../assets/images/shared/arrow-down.svg) no-repeat 10px 15px #5045660d;
  @media screen and (max-width: $phone-Small) {
    width: 240px;
    height: 34px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 300px;
    height: 36px;


  }
  &::placeholder {
    color: #c7c7c7;
    font-size: 18px;
    font-family: $main-font-regular;
    padding-right: 8px;
  }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.valid {
  color: #4cb950;
  padding-right: 22px;
  margin-right: -22px;
  background: url(../../../assets/images/shared/vuesax-bold-tick-circle.svg) no-repeat right 7px;
}

.invalid {
  color: #b41515;
  padding-right: 22px;
  margin-right: -22px;
  background: url(../../../assets/images/shared/vuesax-bold-close-circle.svg) no-repeat right 7px;
}
.valid-1 {
  color: #4cb950;
  padding-right: 17px;
  background: url(../../../assets/images/shared/vuesax-bold-tick-circle.svg) no-repeat right 7px;
}

.invalid-1 {
  color: #b41515;
  padding-right: 17px;
  background: url(../../../assets/images/shared/vuesax-bold-close-circle.svg) no-repeat right 7px;
}

.default-list {
  color: #6c6c6c;
  padding-right: 11px;
  margin-right: 3px;
  background: url(../../../assets/images/shared/default-list-circle.svg) no-repeat right 2px;
  background-size: contain;
}
