
.content-small-admin {
    color: #6c6c6c;
    p:nth-child(1),
    p:nth-child(2),
    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5),
    p:nth-child(6),
    p:nth-child(7),
    p:nth-child(8) {
      color: #12284F;
      margin-top: 5px;
      font-family: DIN Med;
    }
  }
  .content-small-admin-description {
    color: #6c6c6c;
    padding-top: 40px;
    p:nth-child(1){
      color: #12284F;
      font-family: DIN Med;
    }
  }

  .underline-admin {
    color: #12284F;
    text-decoration: underline;
  }

  .Icon-Browse-File-admin {
    filter: invert(10%) sepia(88%) saturate(1453%) hue-rotate(202deg) brightness(40%) contrast(93%);
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    cursor: pointer;
  }

  .details-header-admin {
    // width: 100%;
    // height: 22%;
    display: flex;
    flex-direction: row;
    margin-bottom: 41px;
    color: #12284F;
    font-family: DIN Med;
    font-size: 20px;
  }

.grid-container-admin {
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows:   100px auto;
}

.grid-container-admin-info {
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows:   100px auto;
}

.grid-container-admin-first {
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
}

.details_line-admin {
  border-bottom: 1px solid #cecbd5;
  width: auto;
  opacity: 1;
  margin-bottom: 50px;
  margin-top: 50px;

}

.grid-container-admin-attachment {
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.attachment-sub__title{
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  color: #6c6c6c;
  font-size: 18px;
}
.grid-container-admin-attachment-child{
  color: #333233;
  font-size: 14px;
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: end;
  p:nth-child(1),
  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4),
  p:nth-child(5),
  p:nth-child(6),
  p:nth-child(7),
  p:nth-child(8) {
    color: #12284F;
    margin-top: 7px;
    font-family: DIN Med;
  }
}


@media screen and (max-width: 812px) {
  .email-size{
    width: 50px;
    }
  .details-header-admin {
    flex-direction: row;
  }
  .grid-container-admin {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 50px);

  }
  .grid-container-admin-info {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 50px);
  }
  .grid-container-admin-first {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 50px);

  }
  .grid-container-admin-attachment {
    grid-template-columns: repeat(2, 1fr);

  }
  .content-small-admin {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
  }
  .content-small-admin-description{
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    padding-top: 0px;
  }


}