@import '../../../markup';

.auditor_update_personal_info {
  &-auditorList-container {
    min-width: 80vw;
    min-height: 100vh;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    align-items: center;
    @media screen and (max-width: 812px) {
      width: 100%;
      position: relative;
    }
  }
  &-auditorList {
    background-color: white;
    max-width: 70vw;
    width: 70vw;
    margin-bottom: 50px;
    min-height: 70vh;
    border-radius: 15px;
    margin-top: 11%;
    // margin-right: 4%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    @media screen and (max-width: 812px) {
      max-width: 80%;
      width: 80%;
    }
  }

  &-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 4.5rem;
    row-gap: 1rem;
    @media screen and (max-width: 812px) {
      flex-direction: column;
    }
  }
  &-div-Auditor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-subtitle2 {
    margin-top: 5rem;
    /* UI Properties */
    text-align: right;
    font-family: 'DIN Med';
    letter-spacing: 0px;
    color: $Navy-Blue;
    font-size: 20px;
  }

  &-steps-action {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 2rem;
    margin-top: 20%;
    // position: absolute;
    // left:6rem;
    // bottom: 6rem;
    @media screen and (max-width: 812px) {
    }
  }
}

.update_profile_icon {
  background-image: url('../../../assets/images/shared/update_profile_icon.svg');
  background-repeat: no-repeat;
  background-position: top 1rem center;
  background-size: 1em;
  width: 24px;
  height: 24px;
  padding-top: 10px;
  z-index: 1;
  padding-bottom: 10px;
  filter: brightness(0) saturate(100%) invert(65%) sepia(9%) saturate(489%) hue-rotate(219deg) brightness(89%) contrast(86%);
  margin-left: 2px;
}

.mb4 {
  margin-bottom: 4rem;
}

.auditor_personal_info {
  &-line {
    max-width: 98%;
    color: #fefeff;
    opacity: 0.15;
    margin-top: 5%;
    margin-bottom: 5%;
    @media screen and (max-width: 812px) {
      // display: block;
      margin-top: 14%;
      margin-bottom: 8%;
    }
  }

  &-title {
    font-size: 26px;
    font-family: 'DIN Med';
    margin-bottom: 14px;
    font-weight: bold;
    color: $dark-blue;
  }

  &-subtitle {
    color: $dark-blue;
    font-family: 'DIN Regular';
    font-size: 18px;
  }
  &-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 6rem;
    row-gap: 1rem;
    @media screen and (max-width: 812px) {
      flex-grow: inherit;
    }
  }
  &-wrapper1 {
    display: grid;
    // grid-template-columns: repeat(4, 1fr);
    color: #6c6c6c;
    font-size: 1rem;
    font-family: $main-font-regular;
    @media screen and (max-width: 812px) {
      display: block;
    }
  }
  &-wrapper2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: black;
    color: $dark-blue;
    font-size: 1.1rem;
    margin-top: 10px;
    font-family: $main-font-regular;
    @media screen and (max-width: 812px) {
      margin-top: 0px;
      display: block;
    }
  }

  &-div {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8%;
    @media screen and (max-width: 812px) {
      margin-top: 2rem;
      margin-bottom: 5%;
    }
  }
  &-div-auditor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 812px) {
      flex-direction: column;
    }
  }

  &-subtitle2 {
    margin-top: 5rem;
    text-align: right;
    font-family: 'DIN Med';
    letter-spacing: 0px;
    color: $dark-blue;
    font-size: 20px;
    margin-bottom: 32px;
  }

  &-icon {
    max-width: 14px;
    margin-left: 6px;
    align-items: center;
    margin-top: 6px;
    @media screen and (max-width: 812px) {
      max-width: 18px;
    }
  }
  &-btn {
    align-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    margin-top: 20px;
    background: #797ec4 0% 0% no-repeat padding-box;
    border: 1px solid #797ec4;
    color: white;
    border-radius: $border-radius-medium;
    font-family: 'DIN Med';
    padding-bottom: 5px;
    cursor: pointer;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    &__btn2 {
      background: white 0% 0% no-repeat padding-box;
      border-radius: $border-radius-medium;
      border: 1px solid white;
      color: white;
      width: 180px;
      height: 40px;
      align-items: center;
      align-content: center;
      padding-bottom: 2.8rem;
    }
    @media screen and (max-width: 812px) {
      margin-top: 40px;
      width: 202px;
      margin-left: 4px;
    }
  }

  &-auditorList {
    background-color: white;
    max-width: 70vw;
    width: 70vw;
    margin-bottom: 50px;
    min-height: 70vh;
    border-radius: 15px;
    margin-top: 11%;
    // margin-right: 4%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    @media screen and (max-width: 812px) {
      max-width: 80%;
      width: 80%;
    }
  }
  &-a {
    color: $dark-blue;
    font-weight: bold;
    text-decoration: underline;
  }
  &-p {
    @media screen and (max-width: 812px) {
      margin-bottom: 30px;
    }
  }
  &-auditorList-container {
    min-width: 80vw;
    min-height: 100vh;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    align-items: center;
    @media screen and (max-width: 812px) {
      width: 100%;
      position: relative;
    }
  }
  &-arrow {
    margin-right: 5px;
    color: $dark-blue;
    font-weight: bold;
    width: 20%;
    height: 20%;
  }
  &-family {
    @media screen and (max-width: 812px) {
      display: flex;
      justify-content: flex-start;
      column-gap: 80px;
    }
  }

  &-plus_input_error {
    width: 290px;
    height: 40px;
    background-color: #fff5f5;
    border: 2px solid #db8e8e;
    border-radius: 8px;
    opacity: 1;
    margin-top: 10px;
padding-top: 0rem;
    @media screen and (max-width: 812px) {
      margin-top: 10px;
      // display: inline-block;
      width: 310px;
      height: 36px;
    }
  }
}

.mt-2 {
  margin-bottom: 2rem;
}

// .error-message {
//   color: $dark-red;
//   display: block;
//   font-family: $main-font-regular;
// }

// &-plus_input_error{
//   top: 472px;
//   left: 692px;
//   width: 290px;
//   height: 40px;
//   background-color: #fff5f5;
//   border: 2px solid #db8e8e;
//   border-radius: 8px;
//   opacity: 1;
//   margin-top: 10px;

//   padding-top: 0rem;
//   @media screen and (max-width: 812px) {
//     margin-top: 10px;
//     display: inline-block;
//     width: 310px;
//     height: 36px;
//   }

// }
