:root {
  --customTop: 20.5rem;
  --customMargenRight: 24rem;
}

@media screen and (max-width: 812px) {
  :root {
    --customTop: 22rem;
    --customMargenRight: 1rem;
  }
}

.header-page {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  color: #12284f;
  font-family: DIN Med;
  font-size: 20px;
}

.second-header-page {
  color: #747474;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  font-family: DIN Regular;
  font-size: 15px;
  width: 58%;
  @media screen and (max-width: 812px) {
    width: 98%;
  }
}
.container-page {
  height: auto;
  display: grid;
  margin-top: 40px;
  margin-top: 40px;
  width: auto;
  height: auto;
  background-color: #f1f2f9;
  border-radius: 20px;
  opacity: 1;
  column-gap: 22px;
  &__error {
    @extend .container-page;
    background-color: #fffafa;
    border: 1px solid #e3d5d5;
    border-radius: 20px;
    opacity: 0.9;
  }
}
.header-number {
  color: white;
  width: 55px;
  height: 41px;
  background-color: #686fc7;
  border-radius: 0px 19px;
  opacity: 1;
  text-align: center;
  font-size: 20px;
  &__error {
    @extend .header-number;
    background-color: #db8e8e;
  }
}
.sub-filed {
  display: flex;
  justify-content: space-between;
  width: 88%;
  align-items: center;
  margin-bottom: 55px;
  padding-right: 6%;
  padding-left: 6%;
  @media screen and (max-width: 1170px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 40vw;
    align-self: center;
    justify-self: center;
  }
  @media screen and (max-width: 500px) {
    margin-top: 50px;
    margin-bottom: 90px;
    justify-content: space-around;
  }
}

.container-btn {
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 69px;
  margin-top: 40px;
  width: auto;
  height: 69px;

  background: #f1f2f993;
  border: 1px dashed #513b71;
  border-radius: 20px;
  margin-bottom: 145px;
  // align-items: center;
}
.sub-btn {
  text-align: center;
  font-family: DIN Med;
  color: #686fc7;
  background: top;
}

.add-adutor-btn {
  text-decoration: none;
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 39px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  border-color: #acacac !important;
  background-color: #686fc7;
  border-radius: 30px;
  border-style: none;
  // z-index: 1;
}

.add-adutor-btn:disabled {
  background: #acacac;
  color: #ffffff;
  border-color: #acacac;
  border: 0px;
}

.cancel {
  text-decoration: none;
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 39px;
  color: #686fc7;
  text-align: center;
  overflow: hidden;
  border-color: #686fc7 !important;
  background-color: #ffffff;
  // z-index: 1;
  border: 1px solid #686fc7;
  border-radius: 46px;
}

// POPUP WINDOW
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal{
  z-index: 2;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  padding: 35px 40px;
  border-radius: 3px;
  /* max-width: 600px; */
  min-width: 300px;
  width: 664px;
  height: auto;
  background-color: #ffffff;
  border-radius: 25px;
  display: grid;
  grid-template-columns: 361px 1fr 119px 62px;
  grid-template-rows: 50px 100px 44px;
  margin-top: 40px;
  margin-top: 40px;
  column-gap: 22px;
  @media screen and (max-width: 812px) {
    min-width: 0;
    top: 43%;
    right: -110px;
    width: 219px;
    grid-template-columns: 50px 50px 31px 53px;
    grid-template-rows: 45px 100px 39px;
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.header-popup {
  grid-row-start: 1;
  grid-column-start: 1;
  font-family: DIN Med;
  font-size: 20px;
  @media screen and (max-width: 812px) {
    grid-column-end: 3;
  }
}
.sub-title-popup {
  grid-row-start: 2;
  grid-column-start: 1;
  @media screen and (max-width: 812px) {
    grid-column-end: 5;
  }
}

.cancel_close {
  grid-row-start: 3;
  grid-column-start: 3;

  @extend .cancel;

  @media screen and (max-width: 812px) {
    grid-row-start: 4;
    grid-column-start: 3;
    margin-right: -29px;
    grid-column-end: 5;
  }
}
.close_popup {
  grid-row-start: 3;
  grid-column-start: 2;
  @extend .add-adutor-btn;
  margin-right: -21px;
  @media screen and (max-width: 812px) {
    margin-right: -13px;
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
.icone-close-popup {
  grid-row-start: 1;
  grid-column-start: 4;
  width: 60px;
  height: 60px;
  background-color: #f8f8f8;
  border-radius: 25px 0px 0px 0px;
  opacity: 1;
  margin-top: -35px;
  @media screen and (max-width: 812px) {
    grid-row-start: 1;
    grid-column-start: 4;
  }
}
.add_input_error {
  top: 472px;
  left: 692px;
  width: 290px;
  height: 36px;
  background-color: #fff5f5;
  border: 2px solid #db8e8e;
  border-radius: 8px;
  opacity: 1;
  margin-top: 10px;
  padding-top: 0rem;
}
.header-link-sm {
  margin-right: 3rem;
  font-size: 20px;
  color: #1d203f;
}
@media screen and (max-width: 812px) {
  .btn-sm {
    margin-left: -30px;
  }
  .header-link-sm {
    margin-right: 0em;
  }
}

.ant-message-notice .ant-message-notice-content {
  position: absolute;
  top: 2vh;
  right: 25vw;
  left: 25vw;
  width: 50vw;
  height: 40px;
  background-color: #c3e4c4;
  border-radius: 25px;
  opacity: 1;
  font-family: DIN Regular;
  font-size: 15px;
}

.addAuditorBtns {
  display: flex;
  gap: 26px;
  margin-top: 150px;
  @media screen and (max-width: 812px) {
    margin-left: 5px;
  }
  @media screen and (max-width: 1170px) {
    margin-top: 95px;
  }
}

.add_auditor_input {
  background-color: white;
  width: 16vw;
  height: 24px;
  font-weight: 'bold';
  border-radius: 0.5rem;
  border: 1px solid #6c6c6c44;
  @media screen and (max-width: 1170px) {
    width: 40vw;
  }
}
.orders-details-2 form {
  display: flex;
  flex-direction: column;
}
