@import '../../markup';
html {
  scroll-behavior: smooth;
}

// @media screen and (max-width: $phone-Large) {

// }
// @media screen and (min-width: $phone-Large) and (max-width:$laptop) {

// }
// @media screen and (min-width: $laptop) and (max-width:$custom-laptop) {

// }
body {
  font-family: 'DIN Regular';
}

:root {
  --customPaddingTop: 122px;
  --customPaddingTop2: 122px;
  --customPaddingTopZero: 200px;
}

@media screen and (max-width: 812px) {
  :root {
    --customPaddingTop: 64px;
    --customPaddingTop2: 64px;
    --customPaddingTopZero: 0;
  }
}
.hero-section {
  position: relative;
  height: 110vh;
  background-image: url('../../markup/images/topLayer3.svg'), url('../../markup/images/middleLayer2.png'),
    url('../../markup/images/bottomLayer2.svg');
  background-color: #f8f8f8;
  background-position: right 0 top -85px, right 0 top -100px, right 0 top -70px;
  background-repeat: no-repeat;
  background-size: 100%, 100%, 100%;
  @media screen and (max-width: $tablet-max) {
    height: 85vh;
  }
  @media screen and (max-width: $phone-large) {
    background-size: cover;
    background-position: right 0 top -25px, right 0 top -45px, right 0 top -5px;
    height: 55vh;
  }
}

.nav-container {
  position: absolute;
  height: 100%;
  background-color: #f8f8f8;
  background-image: url('../../markup/images/topLayer3.svg'), url('../../markup/images/bottomLayer2.svg');
  top: -110%;
  background-position-y: top, top;
  background-repeat: no-repeat;
  background-size: 100%, 101%;
  background-color: transparent;
  transition: all 0.5s;
  @media screen and (max-width: $phone-large) {
    background-size: cover;
    background-position: right 0 top -25px, right 0 top -45px;
    width: 100%;
  }
  &--active {
    top: 0;
  }
}

.intro {
  padding: 12vh 130px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  align-items: flex-start;
  @media screen and (max-width: 1300px) {
    padding: 5vh 20px;
  }
}

.intro-title {
  font-family: 'DIN Regular';
  font-size: 68px;
  font-weight: bold;
  padding-bottom: 0.5rem;
  margin: 0;
  @media screen and (max-width: $phone-Large) {
    font-size: 30px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 60px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 60px;
  }
}

.sub-title {
  font-size: 21px;
  color: #ffffff;
  opacity: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 990px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 11px;
  }
}

.about-section {
  background-color: transparent;
  padding: 0 140px;
  max-width: 1260px;

  @media screen and (max-width: $phone-Large) {
    margin-top: 0;
    padding: 0 20px;
    margin-bottom: -100px;
  }
}
#members div:first-child .services-section {
  padding-top: 0 !important;
}

.title {
  display: flex;
  align-items: center;
  color: #514667;
  letter-spacing: 0px;
  font-weight: bolder;
  align-self: flex-start;
  padding-right: 19px;
  padding-bottom: 60px;
  direction: rtl;
  margin-right: -17px;
  font-size: 43px;
  margin-top: 3rem;
  &::before {
    content: url('../../markup/images/purple-dot.svg');
    padding-left: 15px;
  }
  @media screen and (max-width: 990px) {
    font-size: 32px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 22px;
    padding-bottom: 30px;
  }
}
.gov-title {
  display: flex;
  color: #514667;
  letter-spacing: 0px;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center; /* Added this line */
  padding-right: 19px;
  padding-bottom: 20px;
  padding-top: 18px;
  direction: rtl;
  margin-right: -17px;
  font-size: 50px;
  margin-top: 3rem;
  margin-bottom: 80px;
}
.gov-point-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  @media screen and (max-width: $phone-Large) {
    margin-top: 10px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    margin-top: 50px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    margin-top: 50px;
  }
}

.gov-subtitle {
  display: flex;
  align-items: center;
  color: #514667;
  letter-spacing: 0px;
  font-weight: bolder;
  align-self: flex-start;
  padding-right: 19px;
  direction: rtl;
  margin-right: -17px;
  font-size: 43px;
  margin-top: 48px;
  padding-bottom: 25px;
  &::before {
    content: url('../../markup/images/purple-dot.svg');
    padding-left: 15px;
  }
  @media screen and (max-width: 990px) {
    font-size: 32px;
  }
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
}
.gov-p {
  text-align: right;
  // margin-top: 50px;
  // margin-bottom: 120px;
  font-size: 21px;
  letter-spacing: 0px;
  line-height: 1.9;
  color: #4c3b5e;

  @media screen and (max-width: 990px) {
    font-size: 28px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-bottom: 30px;
  }
}
.gov-subtitlelink {
  text-decoration: underline;
  cursor: pointer;
}
.gov-subpoint {
  font-weight: bold;
  font-size: 30px;
  text-align: right;
  margin-bottom: 28px;
  color: #514667;

  @media screen and (max-width: 990px) {
    font-size: 28px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-bottom: 30px;
  }
}
.gov-subpoint-div {
  font-weight: bold;
  font-size: 34px;
  text-align: right;
  margin-bottom: 20px;
  margin-top: 20px ;
  margin-right: 10px;
  color: #514667;

  @media screen and (max-width: 990px) {
    font-size: 35px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
    padding-bottom: 20px;
  }
}
.dot {
  &::before {
    content: url('../../markup/images/StyleDarkDot.svg');
    padding-left: 15px;
  }
}

.about-title {
  display: flex;
  align-items: center;
  color: #514667;
  letter-spacing: 0px;
  font-weight: bolder;
  align-self: flex-start;
  padding-right: 19px;
  padding-bottom: 60px;
  direction: rtl;
  margin-right: -17px;
  font-size: 43px;
  margin-top: 3rem;
  &::before {
    content: url('../../markup/images/purple-dot.svg');
    padding-left: 15px;
  }
  @media screen and (max-width: $phone-Large) {
    font-size: 22px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 32px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 32px;
  }
}
.about-p {
  text-align: right;
  margin-top: 50px;
  margin-bottom: 120px;
  font-size: 21px;
  letter-spacing: 0px;
  line-height: 1.9;
  color: #4c3b5e;

  @media screen and (max-width: $phone-Large) {
    font-size: 18px;
    line-height: 1.6;
    margin-right: 40px;
    margin-left: 38px;
    margin-top: 10px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 20px;
    line-height: 1.6;
    margin-top: 50px;
    margin-right: 40px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 22px;
    line-height: 1.6;
    margin-top: 50px;
    margin-right: 40px;
  }
}

.goals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1450px) {
    margin: 4px;
  }
  @media screen and (max-width: 990px) {
    height: auto;
    margin: 10px;
  }
}
.preview-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.line {
  padding: 0px;
  margin: -20px 0px;
  width: 0px;
  height: 520px;
  border: 1px solid #aea6be2c;
  @media screen and (max-width: 990px) {
    display: none;
  }
}

.goals-line {
  height: 380px;
  background-color: white;
  opacity: 0.2;
  @media screen and (max-width: $phone-Large) {
    height: 0px;
    width: 200px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    display: none;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    display: none;
  }
}

.goals-p {
  text-align: center;
  font-size: 16px;
  margin-top: 14px;
  color: #513b71;
  @media screen and (max-width: 499px) {
    min-width: 220px;
    width: 130px;
    font-size: 17px;
  }

  @media screen and (min-width: 500px) and (max-width: 650px) {
    min-width: 220px;
    font-size: 17px;
  }
  @media screen and (min-width: 650px) and (max-width: 767px) {
    min-width: 240px;
    font-size: 17px;
  }

  @media screen and (min-width: 767px) and (max-width: 1200px) {
    min-width: 220px;
    font-size: 15px;
  }
  @media screen and (min-width: 1200px) {
    min-width: 230px;
    font-size: 17px;
  }
}

.goals-img {
  background: #cbc8d287 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 270px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    max-width: 65%;
    height: 65%;
    object-fit: contain;
  }
  @media screen and (max-width: $phone-Small) {
    width: 230px;
    height: 162px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 210;
    height: 172px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    width: 295px;
    height: 172px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    width: 280px;
    height: 172px;
    justify-content: center;
    align-items: center;
  }
}

.goals-icon {
  @media screen and (max-width: 990px) {
    width: 80px;
  }
  @media screen and (max-width: 480px) {
    width: 140px;
  }
}

.goals-subtitle {
  margin: 0;
  margin-top: 12px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #1b223c;
  @media screen and (max-width: $phone-Large) {
    font-size: 19px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 19px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 25px;
  }
}

.goals-section {
  background-color: transparent;
  padding: 10vh 0px 0vh 0px;
}

.flex-row {
  display: flex;
  &--between {
    justify-content: space-between;

    &--margin {
      margin-bottom: 130px;
      margin-top: 200px;
    }
  }
}
@media screen and (max-width: 1415px) {
  .flex-small {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 990px) {
  .flex-small {
    flex-direction: column;
  }
}
.pt-6 {
  padding-top: 60px;
}
.pb-8 {
  padding-bottom: 80px;
}
@media screen and (max-width: 1600px) {
  .flex-row {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 480px) {
  .flex-row {
    justify-content: center;
  }
}
.goals-title {
  padding-right: 159px;
  @media screen and (max-width: 990px) {
    padding-right: 49px;
  }
}
.features-list {
  margin-top: 120px;
  @media screen and (max-width: $phone-Small) {
    margin-top: 60px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    margin-top: 40px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    margin-top: 180px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    margin-top: 280px;
  }
}
.btn-donate {
  margin-right: 30px;
  @media screen and (max-width: $phone-Small) {
    width: 30px;
  }
}

.btn-do {
  @media screen and (max-width: 990px) {
    width: 191px !important;
    height: 43px !important;
    font-size: 23px !important;
    margin-bottom: 20vh;
  }
  @media screen and (max-width: $phone-Small) {
    width: 140px !important;
    height: 40px !important;
    font-size: 12px !important;
  }
}
.donate-list {
  margin-top: 160px;
  @media screen and (max-width: $phone-Small) {
    font-size: 15px;
    margin-top: -34px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    font-size: 15px;
    margin-top: 20px;
  }
}
.feature-section {
  padding: 8vh 140px 26vh 110px;
  background-color: #f8f8f8;
  background-image: url('../../assets/images/shared/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: $phone-Small) {
    padding: 11vh 40px 16vh 30px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    padding: 22vh 40px 16vh 30px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    padding: 27vh 40px 16vh 30px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    padding: 11vh 30px 16vh 30px;
    background-size: 230%;
  }
}

.feature-title {
  margin-bottom: 0;
  &::before {
    content: url('../../markup/images/StyleDarkDot.svg');
    padding-left: 15px;
  }
}

.feature-subtitle {
  height: 47px;
  font-weight: bold;
  font-size: 22px;
  top: 2811px;
  left: 840px;
  margin-bottom: 40px;
  text-align: right;
  color: #1d203f;

  @media screen and (max-width: $phone-Large) {
    font-size: 14px;
    margin-right: 30px;
    line-height: 2;
    margin-bottom: 40px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 18px;
    margin-right: 30px;
    line-height: 2;
    margin-bottom: 40px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 18px;
    margin-right: 30px;
    line-height: 2;
    margin-bottom: 40px;
  }
}

.feature-item {
  font-size: 20px;
  letter-spacing: 0px;
  margin: 15px 0px;
  color: #4c3b5e;
  &::before {
    content: '';
    border-radius: 50%;
    margin-left: 10px;
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #504566 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
  }
  @media screen and (max-width: $phone-Large) {
    font-size: 15px;
    margin-right: 20px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 20px;
    margin-right: 20px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 24px;
    margin-right: 20px;
  }
}

.feature-img {
  margin-top: 200px;
  max-width: 540px;
  @media screen and (max-width: 1450px) {
    max-width: 380px;
  }
  @media screen and (max-width: 990px) {
    max-width: 250px;
    margin-top: 20px;
  }
}

.services-section {
  background-color: transparent;
  padding: 0vh 115px 0vh 140px;
  @media screen and (max-width: 990px) {
    padding: 0vh 30px 0vh 30px;
  }
}

.services-title {
  margin-top: 0px;
  padding-top: 0px;
}

.services-p {
  max-width: 950px;
  height: 47px;
  font-weight: bold;
  font-size: 20px;
  top: 2811px;
  left: 840px;
  margin-right: 40px;
  margin-bottom: 40px;
  text-align: right;
  color: #1d203f;
  line-height: 1.7;

  @media screen and (max-width: $phone-Large) {
    font-size: 14px;
    margin-right: 30px;
    line-height: 2;
    margin-bottom: 40px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 18px;
    margin-right: 30px;
    line-height: 2;
    margin-bottom: 40px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    font-size: 18px;
    margin-right: 30px;
    line-height: 2;
    margin-bottom: 40px;
  }
}

.services-donate {
  background: #cbc8d288 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 290px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  &::after,
  ::before {
    box-sizing: border-box;
  }
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  @media screen and (max-width: $phone-Small) {
    width: 230px;
    height: 180px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 210;
    height: 192px;
    justify-content: center;
    align-items: center;
  }
}
// .services-donate:hover{
//   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
//   cursor: pointer;

// }
.services-item {
  background: #cbc8d288 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 240px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  &::after,
  ::before {
    box-sizing: border-box;
  }
  @media screen and (max-width: $phone-Small) {
    width: 230px;
    height: 180px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 210;
    height: 192px;
    justify-content: center;
    align-items: center;
  }
}
.services-img {
  width: 100%;
  max-width: 65%;
  height: 55%;
  object-fit: contain;
}
.services-subtitle {
  height: 35px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1d203f;
  &--deactivated {
    color: #393a3e;
  }
  @media screen and (max-width: 990px) {
    height: 0px;
    font-size: 14px;
    margin: 0;
  }
}

.soon-subtitles {
  color: #6b6c73;
}

.btn-container {
  display: none;
  @media screen and (max-width: 1300px) {
    display: block;
  }
}

.intro-btn {
  @media screen and (max-width: 990px) {
    width: 191px !important;
    height: 43px !important;
    font-size: 23px !important;
    margin-bottom: 20vh;
  }
  @media screen and (max-width: 480px) {
    width: 121px !important;
    height: 23px !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 990px) {
  .goals-div {
    justify-content: center;
    flex-direction: column;
    align-content: center;
  }
  .goals-idea-icon {
    width: 100px;
    height: 120px;
  }
  .services-div {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-top: 30px !important;
  }
}

@media screen and (max-width: 1450px) {
  .services-div {
    row-gap: 30px;
    justify-content: space-evenly;
  }
}

.services-img {
  &--deactivated {
    filter: grayscale(100%);
  }
}

@media screen and (max-width: 1415px) {
  .flex-small {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 990px) {
  .flex-small {
    flex-direction: column;
  }
  .feature-icon {
    align-self: center;
  }
}

.side-nav-item {
  display: none;
  @media screen and (max-width: 780px) {
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 0.5px;
    margin-top: 30px;
    font-size: 30px;
    margin-right: 40px;
    font-family: 'DIN Regular';
  }
  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-size: 20px;
    margin-right: 40px;
  }
}
.side-nav {
  display: none;
  position: relative;
  @media screen and (max-width: 780px) {
    top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media screen and (max-width: 480px) {
    top: 60px;
  }
}

.container-names {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 51px;
  padding-left: 179px;
  padding-right: 179px;
  @media screen and (max-width: 812px) {
    justify-content: center;
    gap: 15px;
  }
}
.duration-council {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 179px;
  padding-right: 179px;
  margin-bottom: 50px;
  justify-content: center;
  @media screen and (max-width: 812px) {
    justify-content: center;
    gap: 15px;
  }
}
.item-names {
  justify-content: center;
  display: flex;
  align-items: center;
  background: #e5ecf4;
  border-radius: 12px;
  font-family: 'DIN Med';
  color: #433b6c;
  padding: 15px;
  text-align: center;
  min-width: 220px;
  flex-direction: column;
}
.top-content {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #cbc8d266;
  font-family: 'DIN Med';
  color: #513b71;
  font-size: 20px;
  @media screen and (max-width: 812px) {
    font-size: 12px;
  }
}
.content-flex {
  display: flex;
  margin-top: 40px;
  column-gap: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 40px;
  @media screen and (max-width: 1042px) {
    justify-content: center;
  }
  @media screen and (max-width: 1428px) {
    justify-content: space-around;
  }
}
.content-flex-column {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  min-width: 300px;
  width: 30%;
}
.sub-content {
  font-size: 26px;
  font-family: 'DIN Med';
  color: #513b71;
  text-align: center;
}
.container-box {
  width: 70vw;
  margin-right: 15vw;
  margin-left: 15vw;
}

.title-box {
  text-align: center;
  font-family: 'DIN Med';
  color: #513b71;
  font-size: 35px;
  @media screen and (max-width: 812px) {
    font-size: 27px;
  }
}
.txt-box-1 {
  font-family: 'DIN Med';
  color: #513b71;
  font-size: 18px;
  background-color: #e5ecf4;
  border-radius: 12px;
  @media screen and (max-width: 812px) {
    font-size: 16px;
  }
}
.container-box-2 {
  width: 60vw;
  margin-right: 15vw;
  margin-left: 15vw;
  background-color: #cbc8d266;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 16px;
  padding-right: 5vw;
  padding-left: 5vw;
  row-gap: 18px;
}
.flex-box-2 {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 16px;
  div {
    width: 40%;
    min-width: 160px;
    padding: 2.5%;
  }
}
.flex-box-3 {
  display: flex;
  justify-content: space-around;
  column-gap: 24px;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 16px;
  div {
    width: 17%;
    min-width: 140px;
    padding: 2.5%;
  }
}
.donate-img {
  margin-top: 130px;
  max-width: 450px;
  height: auto;
  margin-left: 40px;
  margin-bottom: 100px;
  @media screen and (max-width: $phone-Small) {
    margin-top: 40px;
    width: 290px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    max-width: 360px;
    margin-top: 40px;
  }

  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    max-width: 450px;
    margin-top: 20px;
    align-items: center;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
  }
}
.wi {
  width: 990px;
  height: 136px;
}
.donate-subtitle {
  height: 47px;
  font-weight: 600;
  font-size: 20px;
  min-width: 0px;
  margin-right: 40px;
  height: 130px;
  text-align: right;
  color: #1d203f;

  @media screen and (max-width: $phone-Small) {
    font-size: 18px;
    width: 260px;
    height: 222px;
    line-height: 1.6;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    font-size: 15px;
    width: 330px;
    height: 150px;
    line-height: 1.8;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    font-size: 20px;
    width: 510px;
    height: 210px;
    line-height: 1.8;
  }

  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
  }
}
.flex-donate {
  display: flex;
  justify-content: flex-start;
  margin-right: 30px;
  column-gap: 40px;
  margin-top: 105px;
  margin-bottom: 120px;

  @media screen and (max-width: $phone-Large) {
    flex-direction: column;
    justify-content: center;
    row-gap: 60px;
    margin-top: 180px;
    align-items: center;
  }

  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    flex-wrap: wrap;
    align-items: center;
    row-gap: 50px;
    column-gap: 80px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    flex-wrap: wrap;
    row-gap: 50px;
    column-gap: 80px;
    align-items: center;
  }
}

@media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
  .custom-flex {
    display: flex;
    justify-content: center;
  }
}

.donate-icons {
  max-width: 0.9rem;
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  cursor: pointer;
}

.flex-services {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  @media screen and (max-width: $phone-Large) {
    flex-direction: column;
    justify-content: center;

    align-items: center;
  }

  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    flex-wrap: wrap;
    align-items: center;
    row-gap: 50px;
    column-gap: 80px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    flex-wrap: wrap;
    row-gap: 50px;
    column-gap: 80px;
    align-items: center;
  }
}

@media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
  .custom-flex {
    display: flex;
    justify-content: center;
  }
}

.donation-link {
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 22px;
  display: flex;
  justify-content: center;
  // align-items: center;
  // text-align: center;
  // &::after,
  // ::before {
  //   cursor: pointer;
  // }

  @media screen and (max-width: 812px) {
    width: 161px;
    font-size: 17px;
    height: 40px;
  }
}
.mb-76 {
  margin-bottom: 76px;
}
.donation-span {
  color: #1b223c;
  text-decoration: underline;
  font-family: 'DIN Med';
  font-size: 21px;
  @media screen and (max-width: 812px) {
    font-size: 16px;
  }
}
.box-icon-close {
  width: 100px;
  height: 80px;

  position: relative;
}

.box2-icon-close {
  position: absolute;
  top: 0;
  left: 0;
}
.flex-modal {
  display: flex;
  justify-content: space-between;
}
.flex-payment {
  display: flex;
  justify-content: space-evenly;
}
.div-modal {
  padding: 30px 40px;
}

// .overlay-payment {
//   width: 100vw;
//   height: 100vh;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   position: fixed;
//  background: rgba(49, 49, 49, 0.8);

// }
.modal-content-payment {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 1.4;
  padding: 35px 0px;
  padding-top: 0px;
  border-radius: 3px;
  max-width: 664px;
  height: auto;
  background-color: #ffffff;
  border-radius: 25px;
  margin-top: 40px;
  column-gap: 22px;

  @media screen and (max-width: 812px) {
    width: 310px;
  }
}

.icone-close-popup-payment {
  width: 77px;
  height: 60px;
  background-color: #f8f8f8;
  border-radius: 25px 0px 0px 0px;
  opacity: 1;
}
.header-popup-payment {
  font-family: DIN Med;
  color: #1d203f;
  font-size: 20px;
  @media screen and (max-width: 812px) {
    font-size: 18px;
  }
}
.sub-title-popup-payment {
  color: #1d203f;
  margin-top: 30px;
  font-family: 'DIN Regular';
  font-size: 16px;

  @media screen and (max-width: 812px) {
    font-size: 15px;
  }
}

.children-popup {
  margin-top: 190px;
  background-color: #cbc8d299;
  width: 800px;
  border-radius: 5px;
  height: 50px;
}
.box-payment-accounts {
  max-width: 740px;
  height: auto;
  margin: 70px auto;
  margin-top: 12px;
  margin-bottom: 2px;
  // padding: 1px;
  @media screen and (max-width: $phone-small) {
    width: 260px;
    height: auto;
  }
}
.span-payment {
  font-size: 18px;
  font-family: 'DIN Med';
  color: #1b223c;
  @media screen and (max-width: 812px) {
    font-size: 14px;
  }
}
.payment-accounts {
  margin: 20px 40px;
  align-items: center;
  justify-items: center;
  background-color: #cbc8d299;
  height: 55px;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  padding: 10px 0px;

  @media screen and (max-width: 812px) {
    gap: 20px;
    margin: 20px 20px;
  }
}
.payment-icons {
  max-width: 3rem;
  padding-right: 0.9rem;
  cursor: pointer;
  @media screen and (max-width: 812px) {
    max-width: 1.5rem;
    padding-right: 0.9rem;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.contact-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  .contact-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    min-height: 200px;
    box-shadow: 0 0 1px 1px #ddd;
    color: #fff !important;
    border-radius: 4px;
    color: #000 !important;
    padding: 20px;
    text-align: center;
    @media screen and (max-width: $phone-Large) {
      width: 200px;
    }
  }
  img {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
    filter: brightness(0);
  }
  a {
    color: #1b223c !important;
  }
}
