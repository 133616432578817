@import "../../../markup";

  .step-btn-2{
    text-decoration: none;
    cursor: pointer;
    font-family: 'DIN Med';
    font-weight: 100;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 39px;
    text-align: center;
    overflow: hidden;
    color: $white;
    border-color: #ACACAC !important;
    background-color:$Lavender-Gray;
    border-radius: 30px;
    border-style: none;
    z-index: 1;
   
    
   
  }
  .step-btn-2:disabled{
    background: #acacac;
    color: $white;
    border-color: #acacac;
    border: 0px;
  }

  .step-btn-3{
    text-decoration: none;
    cursor: pointer;
    font-family: 'DIN Med';
    font-weight: 100;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 39px;
    color: $Lavender-Gray;
    text-align: center;
    border: 1px solid $Lavender-Gray;   
    background-color:$white;
    overflow: hidden;
    border-radius: 30px;
    z-index: 1;
  }

.no-underline {
  text-decoration: none;
}

.wide_button {
  width: 20rem;
  height: 2.7rem;
}