@import '../../../markup';
.logo-header {
  padding: 2rem 7rem;
  margin-bottom: 3.5rem;
  @media screen and (max-width: $phone-Large) {
    padding: 2rem 2rem;
    margin-bottom: 0.8rem;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $custom-laptop) {
    padding: 2rem 2rem;
    margin-bottom: 0.8rem;
  }

  .logo-header__img {
    // width: calc(min(50vw, 140px));
    max-width: 140px;
    @media screen and (max-width: $phone-Large) {
      max-width: 120px;
    }
  }

}
.logo-header_noMargin {
  margin-bottom: 0;
  // padding: 0;
}
.logo-margin {
  margin-top: 1.5rem;
  padding: 0;
}
