@import '../../../markup';

.admin__page--background {
  background-image: url("../../../assets/images/shared/background-light.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
}

.card__link {
  color: #1d203f;
  font-family: $main-font-medium;
  margin-top: 17px;
  font-size: 1rem;
  text-decoration: underline;
  width: fit-content;
  @media screen and (max-width: 650px) {
    margin-left: 53px;
    font-size: 18px;
  }
}

.card__btn {
  height: 5rem;
  align-items: flex-end;
  display: flex;
  justify-content: center;

}

.card__footer {
  display: flex;
  flex-direction: column;
}