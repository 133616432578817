.orders-details {
  background-color: white;
  max-width: 80vw;
  width: 50vw;
  margin-left: 5vw;
  margin-right: 5vw;
  padding-right: 10vw;
  padding-left: 10vw;
  margin-bottom: 50px;
  min-height: 70vh;
  border-radius: 15px;
  // margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 70px;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 60vw;
    padding-right: 5vw;
    padding-left: 5vw;
    // height: 42rem;
  }
}

.application-sub__content {
  display: flex;
  flex-direction: row;
  color: #6c6c6c;
  p:nth-child(1),
  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4) {
    width: 25%;
  }
}

.details-header {
  // width: 100%;
  // height: 22%;
  display: flex;
  flex-direction: row;
  margin-bottom: 41px;
  color: #4c3b5e;
  font-family: DIN Med;
  font-size: 20px;
}

.details_line {
  border-bottom: 1px solid #cecbd5;
  width: auto;
  opacity: 1;
  margin-bottom: 32px;
  margin-top: 32px;
}
.Icon-Browse-File {
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  cursor: pointer;
}

.text-color-content {
  color: #333233;
}

.status-style {
  display: flex;
  justify-content: center;
  height: 38px;
  border-radius: 5px;
  width: 166px;
  height: 38px;
  align-items: baseline;
  text-align: center;
  p:nth-child(1) {
    width: 58%;
  }
}

// GRID
.container-details {
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100px 100px 100px;
}
.content-large {
  grid-row-start: 1;
  grid-row-end: span 4;
  grid-column-start: 1;
  grid-column-end: 2;
}
.content-small {
  color: #6c6c6c;
  p:nth-child(1),
  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4),
  p:nth-child(5),
  p:nth-child(6),
  p:nth-child(7),
  p:nth-child(8) {
    color: #513b71;
    margin-top: 5px;
    font-family: DIN Med;
  }
}

.grid-container {
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
}
.content-small-nested {
  display: grid;
  grid-column: span 4;
  grid-template-columns: 1fr 1fr;
  p:nth-child(3),
  p:nth-child(4),
  p:nth-child(5),
  p:nth-child(6) {
    color: #513b71;
    margin-top: 5px;
    font-family: DIN Med;
    width: 100px;
  }
}

.grid-container-last {
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 160px);
  grid-template-rows: 50px;
  padding-bottom: 20px;
}
.content-small-last {
  color: #6c6c6c;
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: end;
  p:nth-child(1),
  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4),
  p:nth-child(5),
  p:nth-child(6),
  p:nth-child(7),
  p:nth-child(8) {
    color: #513b71;
    margin-top: 7px;
    font-family: DIN Med;
  }
}

.refBtn {
  background-color: #797ec4;
  color: #ffffff;
  padding-right: 60px;
  padding-left: 60px;
  padding-bottom: 10px;
  padding-top: 5px;
  font-size: 18px;
  font-family: 'DIN Regular';
  border-radius: 46px;
}
.refBtnPosition {
  position: absolute;
  top: 40px;
  left: 40px;
  @media screen and (max-width: 490px) {
    position: relative;
    margin-bottom: 100px;
    width: 90%;
    align-self: flex-end;
  }
}
.refBtn:hover {
  background-color: #12284f;
  transition: all 0.4s;
}

.bottomBtns {
  display: flex;
  justify-content: flex-end;
  gap: 26px;
  height: 40px;
  position: absolute;
  left: 4vw;
  bottom: 40px;
  @media screen and (max-width: 812px) {
    flex-direction: column;
    position: relative;
    width: 80%;
    gap: 12px;
    left: 0;
    bottom: 0;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 180px;
  }
}

.bottomBtn {
  padding-right: 40px;
  padding-left: 40px;
  font-family: 'DIN Med';
  background-color: white;
  border: 1px solid #acacac;
  color: #acacac;
  font-size: 18px;
  border-radius: 46px;
  cursor: default;
  @media screen and (max-width: 812px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.acceptBtn {
  background-color: #acacac;
  color: white;
  border: 1px solid #acacac;
}
.activeBtn:hover {
  background-color: #686fc7;
  color: white;
  transition: all 0.4s;
  border: 1px solid #686fc7;
}
.activeBtn {
  background-color: white;
  color: #686fc7;
  border: 1px solid #686fc7;
  cursor: pointer;
}
.activeAcceptBtn {
  background-color: #686fc7;
  color: white;
  border: 1px solid #686fc7;
  cursor: pointer;
}
.activeAcceptBtn:hover {
  background-color: #12284f;
  color: white;
  transition: all 0.4s;
}
// IN SMALL SCREEN

@media screen and (max-width: 812px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 50px);
  }
  .grid-container-1 {
    grid-template-rows: repeat(4, 50px);
  }
  .grid-container-2 {
    grid-template-rows: repeat(6, 50px);
  }
  .grid-container-3 {
    grid-template-rows: repeat(3, 50px);
  }

  .content-small {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
  }

  .content-large {
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .container-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100px 100px 50px 50px 50px 50px 50px 50px 50px 50px;
  }
  .application-sub__content {
    flex-wrap: wrap;
    flex-direction: column;
    p:nth-child(1),
    p:nth-child(2),
    p:nth-child(3),
    p:nth-child(4) {
      width: 38%;
      margin-bottom: 25px;
    }
  }
  .header-link {
    margin-right: auto;
  }
}

@media screen and (max-width: 812px) {
  .details-header {
    flex-direction: row;
  }
  .header-left {
    margin-top: 11rem;
  }
  .orders-details {
    width: 90vw;
    padding: 5vw;
  }
}
.gpa_spacing {
  direction: ltr;
  text-align: end;
}
.spreat-item {
  display: block;
}
.rejection-reason {
  margin-top: -10px;
}
.custom-fields-container .details-header:not(:first-child) {
margin-top: 20px;
}