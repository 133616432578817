.btn-aduter-list {
  text-decoration: none;
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 19px;
  width: 200px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfd;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(to left, #4e5284 50%, #797ec4 50%);
  background-position: left bottom;
  background-size: 201% 100%;
  transition: 0.5s;
  border-radius: 30px;
  border-style: none;
  margin-top: -40%;
  &:hover {
    background-position: right bottom;
    color: white;
  }
  @media screen and (max-width: 812px) {
    width: 161px;
    font-size: 17px;
    height: 40px;
    margin-top: 0%;
  }
}
.search-box-container {
  border-radius: 20px;
  border-color: #0056B3;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.filters-container {
  flex: auto;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap; 
  margin-top: 20px; 
}

@media screen and (max-width: 600px) {
  .filters-container {
    flex-direction: column;
    gap: 16px;
  }
}

.search-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 8px;
  @media screen and (max-width: 600px) {
    grid-template-rows: auto auto;
  }
}
.request-search-input {
  flex: auto;
  padding: 8px;
  margin-top: 20px;
  border-radius: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #F9F9F9;
  width: calc(100% - 16px);
}
.search-input {
  padding: 8px;
  font-size: 16px;
  width: 100%;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}
.table-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
}
.table-name {
  grid-column-start: 1;
}
.table-email {
  grid-column-start: 2;
  grid-row-start: 2;
}
.second-table-email {
  grid-column-start: 2;
  grid-row-start: 3;
}
.third-table-email {
  grid-column-start: 2;
  grid-row-start: 4;
}
.forth-table-email {
  grid-column-start: 2;
  grid-row-start: 4;
}
.five-table-email {
  grid-column-start: 2;
  grid-row-start: 5;
}
.sixth-table-email {
  grid-column-start: 2;
  grid-row-start: 6;
}
.orderes-header-2 {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: row-reverse;
}
.header-left {
  width: 20%;
}
@media screen and (max-width: 565px) {
  .orderes-header-2 {
    flex-direction: row;
  }
}
.status-select-container {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}
.status-select-container label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}
.status-select {
  padding: 10px;
  width: 200px;
  border-radius: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #12284F0D;
}
.date-filter-container {
  align-items: center;
  gap: 10px;
  margin-top: 7px;
}
.date-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px;
  border-radius: 10px;
}
.date-input:focus {
  border-color: #0056B3;
}
label {
  font-weight: bold;
  margin-right: 15px;
}
.filter-button-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
}

.filter-button {
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 39px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  border-color: #acacac !important;
  background-color: #686fc7;
  border-radius: 30px;
  border-style: none;
  margin-top: 40px;
}
.filter-button:disabled {
  background: #acacac;
  color: #ffffff;
  border: 0 #acacac;
}
.export-button {
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 39px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  border-color: #acacac !important;
  background-color: #686fc7;
  border-radius: 30px;
  border-style: none;
  margin-top: 10px;
}
.export-request-button {
  padding: 10px 20px;
  margin-top: 30px;
  margin-left: 10px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  border-color: #acacac !important;
  background-color: #686fc7;
  border-style: none;
  border-radius: 10px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
.count-lable {
  margin-top: 10px;
}