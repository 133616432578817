@import '../../../../../markup';
.app-contact-title {
  font-size: 22px;
  font-family: 'DIN Med';
  margin-bottom: 14px;
  color: #4c3b5e;
  margin-right: 2%;
}

.app-contact-subtitle {
  font-size: 20px/29px;
  font-family: 'DIN Regular';
  color: #6c6c6c;
  margin-top: 8px;
}
.line-form {
  border: 1px solid $mid-purple;
  opacity: 0.15;
  margin-top: 2.5rem;
  margin-bottom: 2.3rem;

  @media screen and (max-width: 1023px) {
    margin-top: 1.75rem;
    margin-bottom: 0;
  }
}

.mb-2 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 60px;
}
.mb-8 {
  margin-bottom: 18rem;
}

.mt-4 {
  margin-top: 1.375rem;
}

.flex-row {
  display: flex;
  // position: relative;
  &--column {
    flex-direction: column;
  }
  &--even {
    align-items: space-evenly;
  }
  &--between {
    justify-content: space-between;
  }
  &--center {
    justify-content: center;
  }
  &--start {
    justify-content: flex-start;
  }
  &--align-center {
    align-items: center;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--gapc {
    column-gap: 4rem;
  }
  &--column-gap {
    column-gap: 30px;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
}

.flex-row-absolute {
  display: flex;
  position: absolute;
  top: 16rem;
  &--column {
    flex-direction: column;
  }
  &--even {
    align-items: space-evenly;
  }
  &--between {
    justify-content: space-between;
  }
  &--center {
    justify-content: center;
  }
  &--start {
    justify-content: flex-start;
  }
  &--align-center {
    align-items: center;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--gapc {
    gap: 2rem;
  }
  &--column-gap {
    column-gap: 30px;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    top: 17.1rem;
    gap: 4.5rem;
    width: 74%;
  }
}

.inputTextCon {
  direction: ltr;
  text-align: end;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #584f70;
  border: 1px solid #50456698;
  border-radius: $border-radius-small;
  width: 210;
  height: 35px;
  background-color: white;
  margin-top: 10px;
  outline: none;
  &:disabled {
    -webkit-text-fill-color: #584f70;
    background-color: #efefef;
  }

  @media only screen and (min-width: 361px) and (max-width: 427px) {
    width: 100%;
    margin-top: 5px;
  }
}
