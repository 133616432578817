.auditorList {
  background-color: white;
  max-width: 70vw;
  width: 70vw;
  margin-bottom: 50px;
  min-height: 70vh;
  border-radius: 15px;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-self: center;
  @media screen and (max-width: 812px) {
    max-width: 80%;
    width: 80%;
    margin-right: 2%;
    margin-left: 2%;
    padding: 5%;
  }
}

.auditor-header {
  font-size: 28px;
  font-family: 'DIN Bold';
  color: #12284f;
}

.auditor-subtitle {
  font-size: 18px;
  font-family: 'DIN Regular';
  margin-top: 8px;
}

.auditorList-content {
  background-color: #fff;
  min-height: 58vh;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  position: relative;
  overflow-x: auto;
}
.auditorList ::-webkit-scrollbar {
  width: 1px;
}
.auditorList ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.auditorList ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.auditorList ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.auditor-table {
  font-family: 'DIN Regular';
  color: #12284f;
  border-radius: 4px;
  border-collapse: collapse;
  font-size: 16px;
  margin-top: 56px;
  .table-head {
    text-align: right;
    background-color: #f1f2f9;
    height: 48px;
  }
  td,
  th {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  tbody {
    font-family: 'DIN Regular';
    color: #030303;
    font-size: 16px;
  }
  tr {
    border-bottom: 1px solid #f1f2f9;
  }
}

.table-status {
  width: 45%;
  min-width: 50px;
  padding-top: 3px;
  margin-top: -3px;
  padding-bottom: 7px;
  margin-bottom: -7px;
  border-radius: 10px;
  text-align: center;
  font-family: 'DIN Med';
  font-size: 14px;
}
.table-status-2 {
  width: auto;
  min-width: 50px;
  padding-top: 3px;
  margin-top: -3px;
  padding-bottom: 7px;
  margin-bottom: -7px;
  border-radius: 10px;
  text-align: center;
  font-family: 'DIN Med';
  font-size: 14px;
}

.table-procedures {
  border: 1px solid #12284f;
  border-radius: 46px;
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 3px;
  padding-bottom: 4px;
  margin-bottom: -4px;
  color: #12284f;
  min-width: 80px;
}

.table-procedures-btn {
  border: 1px solid #797ec4;
  border-radius: 46px;
  // width: 50%;
  display: flex;
  justify-content: center;
  gap: 3px;
  padding-bottom: 4px;
  margin-bottom: -4px;
  color: #797ec4;
  min-width: 80px;
  background-color: white;
  font-family: 'DIN Med';
}
.table-procedures-btn.btn-danger {
  border: 1px solid #c2180d;
  color: #c2180d;
}
.table-procedures-btn-admin {
  border: 1px solid #797ec4;
  border-radius: 46px;
  width: 40%;
  display: flex;
  justify-content: center;
  gap: 3px;
  padding-bottom: 4px;
  margin-bottom: -4px;
  color: #797ec4;
  min-width: 80px;
  background-color: white;
  font-family: 'DIN Med';
  @media screen and (max-width: 812px) {
    width: auto;
  }
}

.pagination {
  width: 240px;
  height: 30px;
  align-self: center;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  color: #6c6c6c;

  p {
    cursor: pointer;
  }
  p:hover {
    color: #686fc7;
  }
  .currentPage {
    color: #686fc7;
    font-family: 'DIN Med';
  }
  .disablePage {
    color: rgba(108, 108, 108, 0.33);
    cursor: default;
  }
  .disablePage:hover {
    color: rgba(108, 108, 108, 0.33);
  }
}

.no-orders {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}
.no-orders-img {
  width: 40%;
  max-width: 150px;
  min-width: 110px;
}

.no-orders-texts {
  color: #12284f;
  text-align: center;
  margin-top: 30px;
  font-size: 17px;
  font-family: 'DIN Med';
  p:nth-child(2) {
    margin-top: 5px;
    font-family: 'DIN Regular';
  }
}

.newReqApplicant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn-disabled {
  color: white;
  background: #a8a8a8;
  cursor: default;
  pointer-events: none !important;
  &:hover {
    border: 0px;
  }
}

.mobile-list {
  width: 100%;
  display: flex;
  font-size: 17px;
  height: 200px;
}
.mobile-head {
  width: 40%;
  display: flex;
  flex-direction: column;
  // row-gap: 32px;
  font-family: 'DIN MED';
  justify-content: space-between;
}
.mobile-data {
  width: 60%;
  display: flex;
  flex-direction: column;
  // row-gap: 32px;
  justify-content: space-between;
}
.mobile-hr {
  height: 2px;
  background-color: #f1f2f9;
  width: 114%;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: -7%;
}
