// global variables
$border-radius-medium: 1.5rem !default;
$border-radius-xmedium: 1rem !default;
$border-radius-small: 0.5rem !default;
$border-radius-xsmall: 0.25rem !default;

// media queries
$phone-small: 375px;
$phone-large: 768px;
$tablet-max: 1366px;
$laptop-max: 1600px;
$desktop-max: 1920px;

// fonts
$main-font-regular: 'DIN Regular';
$main-font-medium: 'DIN Med';
$main-font-bold: 'DIN Bold';

// animation variables
$time-default: 0.3s !default;
$time-fast: $time-default * 0.5 !default;
$time-smooth: $time-default * 3 !default;
$scroll-animation-time: $time-smooth * 1.5 !default;




//new special media queries //
$phone-Small:480px;
$phone-Large: 768px;
$tablet: 992px;
$tablet-Max:1024px;
$laptop:1200px;
$custom-laptop:1600px;
$desktop-Max:1920px;

