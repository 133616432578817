@import "../../../markup/";

.avatar {
  border-radius: 50%;
  width: 229px;
  height: 124px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.avatar-border {
  border-radius: 50%;
}

.avatar-error {
  color: $dark-red;
  display: block;
  font-size: 1rem;
  font-family: $main-font-regular;
  position: absolute;
  width: 24%;
  text-align: center;
}

.avatar-input {
  display: none;
}

@media screen and (max-width: 1600px) {
  .avatar {
    justify-content: center;
    margin-top: 0px;
    // margin-bottom: 20px;
  }
}