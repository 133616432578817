/// Backdrop
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #4e4a55;
  display: none;
  opacity: 0.5;
  z-index: 1;

  @media screen and (min-width: 812px) {
    display: none;
  }
}

.backdrop[data-open='true'] {
  display: block;

  @media screen and (min-width: 812px) {
    display: none;
  }
}

// toolbar admin

.tool-bar-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  height: 72px;
  border-radius: 0 0 15px 15px;

  @media screen and (min-width: 812px) {
    display: none;
  }
}

.tool-bar-admin[data-account-type='1'] {
  background-color: #12284f;
}

.tool-bar-admin[data-account-type='2'] {
  background-color: #12284f;
}

.tool-bar-admin[data-account-type='3'],
.tool-bar-admin[data-account-type='4'] {
  background-color: #504566;
}
.tool-bar-admin[data-account-type='4'] {
  background-color: #504566;
}

// small nav

.SmallNav {
  position: fixed;
  color: #fff;
  top: 0;
  left: 0;
  height: 100vh;
  width: 288px;
  border-radius: 0 15px 15px 0;
  background: #ffffff 0 0 no-repeat padding-box;
  z-index: 2;
  transition: all 0.4s;
  direction: ltr;
  transform: translateX(-100%);

  @media screen and (min-width: 812px) {
    display: none;
  }
}

.SmallNav[data-open='true'] {
  transform: translateX(0);

  @media screen and (min-width: 812px) {
    display: none;
  }
}

.SmallNav > .menu {
  padding-top: 32px;
}

.SmallNav > .menu > .menu-item {
  color: #12284f;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 300;
  border-bottom: 1px solid #f1f2f9;
  flex-direction: row-reverse;
}

.SmallNav > .menu > .menu-item > span {
  background-repeat: no-repeat;
  background-position: top 1rem center;
  background-size: 1em;
  width: 24px;
  height: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  filter: brightness(0) saturate(100%) invert(65%) sepia(9%) saturate(489%) hue-rotate(219deg) brightness(89%) contrast(86%);
  margin-left: 2px;
}

.SmallNav > .menu > .menu-item[aria-current='page'] {
  background-color: #fff;
  color: rgb(23, 105, 176);
}

.SmallNav > .menu > .menu-item:hover {
  background-color: #fff;
  color: rgb(23, 105, 176);
}

@media screen and (max-width: 812px) {
  .user-name-1 {
    color: #12284f;
    margin-left: 53px;
  }
  .user-name-2 {
    color: #787a7c;
  }
}

.btnHover:hover {
  color: #12284f;
  background-color: #ffffff77;
}

// side navbar
nav {
  // fix navbar position to right
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  // size and shape
  border-radius: 15px 0 0 15px;
  height: 100vh;
  width: 20vw;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  // navbar in mobile
  @media screen and (max-width: 812px) {
    display: none;
  }
}

// background color based on account type
nav[data-account-type='1'] {
  background-color: #12284f;
}

nav[data-account-type='2'] {
  background-color: #12284f;
}

nav[data-account-type='3'],
nav[data-account-type='4'] {
  background-color: #504566;
}
nav[data-account-type='4'] {
  background-color: #504566;
}

nav > hr {
  margin: 20px 0 20px;
  width: 100%;
  border: 0.1px solid #f8f8f8;
  @media screen and (max-width: 990px) {
    display: none;
  }
}

// menu
nav > .menu {
  width: 100%;
  flex-grow: 1;
}

// menu items
nav > .menu > .menu-item {
  height: 60px;
  color: #968fa3;

  display: flex;
  align-items: center;
  padding-right: 23%;
  font-size: 18px;
}

// menu items - icon
nav > .menu > .menu-item > span {
  background-repeat: no-repeat;
  background-position: top 1rem center;
  background-size: 1em;
  width: 24px;
  height: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  filter: brightness(0) saturate(100%) invert(65%) sepia(9%) saturate(489%) hue-rotate(219deg) brightness(89%) contrast(86%);
  margin-left: 2px;
}

//active state
nav > .menu > .menu-item[aria-current='page'] {
  color: white;
}

nav > .menu > .menu-item[aria-current='page'] > span {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(24%) hue-rotate(114deg) brightness(108%) contrast(108%);
}

nav[data-account-type='1'] > .menu > .menu-item[aria-current='page'] {
  background-color: #2d61bc;
}

nav[data-account-type='2'] > .menu > .menu-item[aria-current='page'] {
  background-color: #dae7e741;
}

// hover state
nav > .menu > .menu-item:hover {
  color: white;
}

nav[data-account-type='1'] > .menu > .menu-item:hover {
  background-color: #2d61bc;
}

nav[data-account-type='2'] > .menu > .menu-item:hover {
  background-color: #dae7e741;
}

nav[data-account-type='3'] > .menu > .menu-item:hover,
nav[data-account-type='4'] > .menu > .menu-item:hover {
  background-color: #ffffff77;
}

nav > .menu > .menu-item:hover > span {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(24%) hue-rotate(114deg) brightness(108%) contrast(108%);
}

// user section
nav > .user-section {
  width: 70%;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-right: 15%;
  padding-left: 15%;
  @media screen and (max-width: 1300px) {
    width: 60%;
    padding-right: 20%;
    padding-left: 20%;
  }
  @media screen and (max-width: 1084px) {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

// user section - profile image
nav > .user-section > div > .user-profile-img {
  margin-top: 27%;
  width: 75%;
}

.flex-none {
  flex: none;
}

// user section - username
nav > .user-section > .user-name {
  padding-right: 2%;
  padding-left: 9%;
  font-size: 18px;
  p:nth-child(2) {
    font-size: 13px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 15px;
    p:nth-child(2) {
      font-size: 10px;
    }
  }
}

// user section - logout
nav > .user-section > .logout-container {
  margin-top: 2%;
  height: 37px;
  width: 37px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  cursor: pointer;
}

nav[data-account-type='1'] > .user-section > .logout-container {
  background-color: #686fc7;
}

nav[data-account-type='2'] > .user-section > .logout-container {
  background-color: #686fc7;
}

nav > .user-section > .logout-container > .logout-icon {
  width: 27px;
  height: 26px;
  filter: brightness(0) saturate(100%) invert(184%) sepia(9%) saturate(489%) hue-rotate(219deg) brightness(89%) contrast(86%);
}

.user-section-small {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 32px;
  justify-content: space-around;
  text-align: right;
  .logout-container {
    background-color: #686fc7;
    height: 37px;
    width: 37px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    .logout-icon {
      filter: brightness(0) invert(1);
      width: 21px;
      height: 20px;
      padding-right: 2px;
    }
  }
}
