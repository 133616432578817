@import "../../../markup";





.form-check {

    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content:flex-start ;


    &__graduate{
      margin-top: 6px;
      padding-top: 0;
      display: flex;
      flex-direction: row;
      justify-content:flex-start ;
      align-items: center;
   }

   &__check-labelu {
    color: $purple;
    font-size: 17px;
    font-family: $main-font-regular;
    margin-right: 39px;
    display: inline-block;
  
  }
      
      
      

    
    
    

  //     position: relative;

  // position: absolute;
  //      top:16rem;
  //  left:32rem
    }
  

 
  
  
  .form-checkbox {
    appearance: none;
    background-color: #fff ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
    margin-left: -1.5em;
    border: 1px solid $purple;
    border-radius: 5px;
    cursor: pointer;
    flex: none;
 
    &:focus {
      box-shadow: none;
      border-color: #504566;
    }
  }
  
  .form-checkbox:checked[type="checkbox"] {
    background-image: url("../../../assets/images/shared/check.svg")  !important;
    background-color: $purple;
    background-size: 14px 11px;
    background-position: center;
  }
  
  .checbox-label {
    color: $purple;
    font-size: 16px;
    font-family: $main-font-regular;
    margin-right: 30px;
    display: inline-block;
    margin-top: -1px;
  }
  
  @media screen and (max-width: 1600px) {
    .form-check {
      display: flex;
      align-items: flex-start;
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 780px) {
    .form-checkbox--last {
      width: 30px;
      height: 27px;
    }
  }
  @media screen and (max-width: 600px) {
    .form-checkbox--last {
      width: 35px;
    }
  }
  @media screen and (max-width: 480px) {
    .form-check--last {
      display: flex;
    }
    .form-checkbox {
      width: 17px;
      height: 17px;
    }
    .form-checkbox--last {
      width: 40px;
      height: 24px;
    }
    .checbox-label {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 360px) {
    .form-checkbox--last {
      width: 60px;
      height: 24px;
    }
    .flexible-checkbox{
      min-width: 17px;
    }
  }
  
