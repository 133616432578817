@import '../../../../../markup';

.form-select {
  color: #4c3b5e;
  padding: 1px 15px;
  height: 44px;
  width: 267px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 1em;
  appearance: none;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  margin-top: 30px;
  color: #6c6c6c;
  font-size: 20px;
}

.form-input{
  padding-right: 8px;
  padding-left: 8px;
  font-size: 16px;
  // font-family: DIN;
  font-family: $main-font-regular;

  width: 227px;
  height: 36px;
  border: 1px solid #50456698;
  border-radius: 8px;
  opacity: 1;
  text-align: right;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mt-2 {
  margin-top: 3rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

// @media screen and (max-width:480px) {
//   .form-select {
//     width: 227px;
//     height: 30px;
//     font-size: 15px;
//   }
// }





// @media screen and (max-width: 428px) {
//   .form-select {
//     width: 285px;
//     height: 38px;
//     font-size: 16px;
// }
// }

// @media only screen and (min-width: 361px) and (max-width: 427px) {
//   .form-select {
//     width: 254px;
//   }
// }

// @media screen and (max-width: 360px ) {
//   .form-select {
//     width: 254px;
//   }
// }


@media screen and (max-width: 360px) {
  .form-select { 
    width: 227px;
    height: 36px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 427px) {
  .form-select { 
    width: 100%;
    height: 38px;
  }
}

@media only screen and (min-width: 428px) and (max-width: 1023px) {
  .form-select { 
    width: 102%;
    height: 40px;
  }
}


.error-text {
  color: #b41515;
  position: absolute;


}


.app-contact-title {
  font-size: 22px;
  font-family: 'DIN Med';
  margin-bottom: 14px;
  color: #4C3B5E;
}

.app-contact-subtitle {
  font-size: 20px/29px;
  font-family: 'DIN Regular';
  color: #6C6C6C;
  margin-top: 8px;
}


.mb-2 {
  margin-bottom: 4rem;
}

.mb-4 {
  margin-bottom: 60px;
}

.flex-row {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--even {
    align-items: space-evenly;
  }

  &--between {
    justify-content: space-between;
  }

  &--center {
    justify-content: center;
  }

  &--start {
    justify-content: flex-start;
  }

  &--align-center {
    align-items: center;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--gap {
    gap: 40px;
  }

  &--column-gap {
    column-gap: 30px;
  }
}

.form-group-select{
  padding-top: 0;
}



.AttachExCertificates {
  display: block;
  margin-bottom: 10px;
  // margin-top: 40px;
  color: #6c6c6c;
  font-size: 20px;
  margin-top: 4rem;

  @media screen and (max-width: 767px) {
    margin-top: 2rem;
  }
  
}