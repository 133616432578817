@import '../../markup';

.bg-404 {
  background-image: url('../../markup//images/bg404lg.png');
  background-repeat: no-repeat;
  background-size: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
}
.d-flex-404 {
  display: flex;
  justify-content: center;
  direction: ltr;
}
.container-404 {
  // width: 70%;

  width: 100%;
  direction: rtl;
  margin-left: 140px;
  margin-right: 156px;
  margin-top: 33px;
  margin-bottom: 52px;
}
.pg-404-img {
  /* in small divice 
    max-width: 320px;
    margin-right: -22px;
    */
  max-width: 600px;
  float: left;
  margin-right: 20px;
  margin-bottom: 60px;
  @media screen and (max-width:480px) {
    max-width: 320px;
    margin-right: -22px;
  }
}
.txt-box {
  color: #1b223c;
  font-size: 22px;
}
.header-404 {
  color: #1b223c;
  font-family: 'DIN Bold';
  font-size: 27px;
}
.content-404 {
  margin-top: 30px;
}
