
html {
  height: 100%;
  text-size-adjust: 100%;
  font-size: 16px !important;

  body {
    scroll-behavior: smooth;
    direction: rtl;
    margin: 0;
    height: 100%;
    min-width: 320px;
    max-width: 100vw !important;
    font-size: 16px !important;
    animation: fadeIn $time-smooth forwards;
    -webkit-tap-highlight-color: $highlight;
    -webkit-font-smoothing: antialiased;
  }

  p,
  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: initial;
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  nav li {
    display: inline-block;
  }

  input,
  button {
    border: 0;
    outline: none;
    cursor: pointer;
  }
}

@media only print {
  html,
  body {
    overflow: visible;
    -webkit-print-color-adjust: exact;
  }
}
