@import '../../../markup';

.footer {

padding: 20px 140px;
// height: 245px;
  background-color: #1B223C;
  color: white;
  // margin-top: 0px;
  font-family:'DIN Med';
}
.box-footer{
  width: 260px;
  @media screen and (max-width: $phone-Large) {
    width: 220px;
}

@media screen and (min-width: $phone-Large) and (max-width:$laptop) {}



  @media screen and (min-width: $laptop) and (max-width:$custom-laptop) {

  }
}

.mt-footer{
margin-top: 7rem;
margin-bottom: 0px;
}


.mt-info{
  @media screen and (max-width: $phone-Large) {
margin-top: 30px;}
}




.mt-Footer{
  margin-top: 2rem;
}
.flex-footer{
  display: flex;
  justify-content: flex-start;
  column-gap: 140px;
  @media screen and (max-width: $phone-Large) {
    flex-direction: column;
    justify-content: center;
    
    align-items: center;}

    @media screen and (min-width: $phone-Large) and (max-width:$laptop) {
      // grid-template-columns: repeat(3, 1fr);
      flex-wrap: wrap;
    align-items: center;
    row-gap: 50px;
    column-gap: 80px;
      // grid-template-rows: repeat(4, 1fr);
      // gap: 60px;
    }
    @media screen and (min-width: $laptop) and (max-width:$custom-laptop) {
      flex-wrap: wrap;
      row-gap: 50px;
      column-gap: 80px;
    align-items: center;
      // grid-template-columns: repeat(3, 1fr);
      // // grid-template-rows: repeat(2, 1fr);
      // gap: 50px;
    }
   
  // margin-bottom: 30px;
  
}

.flex-info{
  display: flex;
  justify-content:space-between;
  gap: 40px;
  margin-top: 25px;
}
.footerLogo {
  width: 130px;
  height: auto;
  @media screen and (max-width: $phone-Large) {
    width: 120px;
}
}
.modren {
display: flex;
justify-content: space-between;
}
.footer-div-end{
  display: flex;
  justify-content: space-between;
  gap:20px;
  align-items: center;
  @media screen and (max-width: $phone-Large) {
    gap:20px;
    justify-content: center;
    margin-top: 44px;
    margin-bottom: 20px;


}

}
.footer-item {
  // min-width: 180px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.emailLogo,
.mobileLogo,
.locationLogo {
  margin-left: 10px;
}
.emailLogo {
  padding-top: 7px;
}
.tamkeenLogo {
  width: 190px;
}

@media screen and (max-width: 1600px) {
  .footer-item:last-child {
    margin-top: 10px;
  }
  .emailLogo,
  .mobileLogo,
  .locationLogo {
    height: 20px;
    width: 20px;
  }
  .footer {
    padding: 24px;
  }
}

@media screen and (max-width: 540px) {
  .footer-end {
    row-gap: 10px;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .footer-item {
    margin-top: 10px;
  }
  .footer-container {
    justify-content: center;
  }
  .footer {
    text-align: center;
  }
}

.flex-row {
  display: flex;

  &--column {
    flex-direction: column;
  }
  &--between {
    justify-content: space-between;
  }
  &--start {
    justify-content: flex-start;
  }
  &--align-center {
    align-items: center;
  }
  &--gap{
    gap: 140px;
  }
}

.mt-4 {
  margin-top: 40px;
}

.pt-2 {
  padding-top: 20px;
}

@media screen and (max-width: 1600px) {
  .flex-row {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 480px) {
  .flex-row {
    justify-content: center;
  }
}
