@import '../../../markup';

.card {
  padding: 2.5rem;
  border-radius: $border-radius-medium;
  background-color: white;
  overflow: hidden;
  z-index: 1;
  width: 20rem;
  margin-bottom: 4.5rem;
  @media screen and (max-width: $phone-Small) {
    width: 16rem;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 20rem;
  }



  &--wide-1 {
    width: 45rem;
    margin-bottom: 4.5rem;
    @media screen and (max-width: $phone-Small) {
      width: 16rem;
    }
    @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
      width: 20rem;
    }

    @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
      width: 42rem;

    }
  }

  &--wide-2 {
    //top-right-buttoon-left
    padding: 1.2rem 1.2rem 4rem 1.2rem;
    width: 25rem;
    margin-bottom: 4.5rem;
    @media screen and (max-width: $phone-Small) {
      padding: 0.9rem 1.2rem 4rem 1.2rem;
      width: 16rem;
    }
    @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
      padding: 0.9rem 1.2rem 4rem 1.2rem;
      width: 20rem;
    }
  }

  .card__header {
    color: $dark-purple;
  }

  .card__header__otp {
    color: $dark-purple;
    padding-right: 1rem;
  }

  .card__header__title {
    font-family: $main-font-bold;
    font-size: 1.6rem;
    padding-bottom: 1rem;
    @media screen and (max-width: $phone-Large) {
      font-size: 1rem;
    }
  }

  .card__header__subtitle {
    font-family: $main-font-regular;
    color: $darker-purple;
    font-size: 1.4rem;
    @media screen and (max-width: $phone-Small) {
      font-size: 0.9rem;
 
    }
    @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
      font-size:1rem;
   
    }
  }

  .card__header__subtitle2 {
    font-family: $main-font-medium;
    color: $purple;
    font-size: 1.4rem;
  }

  .card__body {
    &--wide {
      // align-content: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 2rem;
    }
  }
}
