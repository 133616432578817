@import '../../../../../markup';
.additoinal-fields-subtitle {
  padding: 10px;
  color: #fff;
  background: linear-gradient(-45deg, #513b70, #b3a2c6,#98b1d6);
  &:not(:first-child) {
    margin-top: 3rem;
  }
  @media (max-width: 768px) {
    background: #513b70;
  }
}
.checbox-style input {
  background-size: 18px 8px;
  width: 16px;
  height: 16px;
  font-size: 20px;
  border-radius: 4px
}