@import '../../../markup';
@import '../../../markup/variables/fonts';

.bg-ApplicantForm {
  min-width: 80vw;
  min-height: 110vh;
  // min-height: 100vh;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
}
.row-header {
  display: flex;
  flex-direction: row;
}

.col-header {
  display: flex;
  flex-direction: column;
}

.header-link {
  margin-right: 6rem;
  display: flex;
  margin-top: 7rem;
}

.arrow_ {
  border: 2px solid #513b71;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  width: 2px;
  height: 2px;
  margin-left: 1rem;
  margin-right: 10px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.underline {
  color: #513b71;
  text-decoration: underline;
}

.row-step {
  display: flex;
  flex-direction: row;
  // padding-right: 2rem;
}

.col-step-1 {
  height: 620px !important;
  @media only screen and (max-width: 900px) {
    height: 680px !important;
  }
}

.steps-content {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-medium;
  padding: 4.5rem;
  background-color: white;
  // overflow: hidden;
  // z-index: 0;
  // width: 24rem;
  margin-right: 44rem;
  width: 50.688rem;
  height: 589px;
  position: absolute;
  top: 168px;
  left: 39px;

  // @media screen and (min-width: 768px) {
  //   padding: 1.24rem;
  //   height: auto;
  //   width: auto;
  //   margin-right: 0px;
  //   width: 80%;
  //   margin-right: 0;
  //  }

  @media only screen and (min-width: 768px) and (max-width: 812px) {
    padding: 1.24rem;
    height: auto;
    width: auto;
    margin-right: 0px;
    width: 70%;
    margin-right: 0;
    left: 19px;
  }

  @media only screen and (min-width: 813px) and (max-width: 1023px) {
    padding: 1.24rem;
    height: auto;
    width: auto;
    margin-right: 0px;
    width: 50%;
    margin-right: 0;
    left: 19px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1600px) {
    padding: 1.24rem;
    height: auto;
    width: auto;
    margin-right: 0px;
    width: 60%;
    margin-right: 0;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    width: 90%;
    // justify-items: center;
    // justify-content: center;
    padding: 1rem;
    left: 12%;
  }
}

// .steps-content1{
//   margin-top: 2rem;
//   border-radius: $border-radius-medium;
//   background-color: rgb(61, 35, 35);
//   margin:0 auto;
//   width: 90%;
//   justify-content: start;
// }

.description-desc {
  text-align: start;
  margin-right: 6px;
  color: #513b71;
  font-family: DIN Med;
  //  margin-bottom: 15px;
  font-size: 20px;
}

.steps-action {
  display: flex;
  flex-direction: row-reverse;
  bottom: 2.2rem;
  left: 62px;
  position: absolute;
  @media only screen and (min-width: 768px) and (max-width: 900px) {
    bottom: 0.4rem;
    left: 1rem;
    margin-bottom: 1.125rem;
  }
}

.steps-action2 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3rem;
  margin-bottom: 2.2rem;
  justify-content: center;
  // left: 12%;
  // position: absolute;
}

.step-btn {
  text-decoration: none;
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 39px;
  color: #513b71;
  text-align: center;
  overflow: hidden;
  border-color: #acacac !important;
  background-color: #becede;
  border-radius: 30px;
  border-style: none;
  z-index: 1;

  // &:hover {
  //   background-position: right bottom;
  //   color: white;
  //   // border: 1px solid white;
  // }
}
.step-btn:disabled {
  background: #acacac;
  color: #ffffff;
  border-color: #acacac;
  border: 0px;
}

.step-btn-prev {
  text-decoration: none;
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 39px;
  color: #513b71;
  text-align: center;
  overflow: hidden;
  border-color: #513b71 !important;
  background-color: #ffffff;
  z-index: 1;
  border: 1px solid #acacac;
  border-radius: 46px;
  margin-left: 31px;
}

.step-btn-prev:disabled {
  border: 1px solid;
  border-color: #acacac !important;
  border-radius: 46px;
  color: #acacac;
  margin-left: 31px;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #f0eef5;
  border: 2px dashed #513a71;
}

.anticon svg {
  display: none;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #513b70;
  border: 2px dashed #513b71;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #ffffff;
  border: 1px solid #f0eef5;
}

// for hide number

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  display: none;
}

// for hide number

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  display: none;
}

// for make arrow more big
.ant-steps .ant-steps-item-container {
  outline: none;
  margin-bottom: 3rem;
}

// bg of arrow

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #513b71;
}

// enhance the start of arrow from top

.ant-steps.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: -7px;
  height: 110%;
}

// color of steps title

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #989898;
}

// for make title and dec in one line

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: inline-block;
  margin-left: -5rem;
}

/// color of title
.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #989898;
}

.ant-steps .ant-steps-item-title {
  font-size: 13px;
  font-family: DIN Regular;
}

// color of desc

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #1b223c;
  font-family: DIN Med;
}
.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #1b223c;
  font-family: DIN Regular;
}
.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #1b223c;
  font-family: DIN Regular;
}

.ant-steps-item-icon {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #513b71 !important;
  border: none !important;
  margin-right: -10px !important;
}
.css-4ff9q7.Mui-completed {
  color: #513b71 !important;
  border: none !important;
  margin-right: -10px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.2rem !important;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #aea1c3 !important;
  opacity: 1 !important;
  color: #ffffff !important;
  border-radius: 100% !important;
  margin-right: -10px !important;
}
.css-4ff9q7 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.2rem !important;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #aea1c3 !important;
  opacity: 1 !important;
  color: #ffffff !important;
  border-radius: 100% !important;
  margin-right: -10px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #f4f3f7 !important;
  border: 2px dashed #aea1c3 !important;
  border-radius: 100% !important;
  opacity: 1 !important;
  margin-right: -10px !important;
}
.css-4ff9q7.Mui-active {
  color: #f4f3f7 !important;
  border: 2px dashed #aea1c3 !important;
  border-radius: 100% !important;
  opacity: 1 !important;
  margin-right: -10px !important;
}

.MuiStepConnector-line.MuiStepConnector-lineVertical.css-8t49rw-MuiStepConnector-line {
  display: block !important;
  border-color: #bdb6cf73 !important;
  border-left-style: none;
  border-left-width: 0;

  margin-right: 7px !important;
  border-right-style: solid !important;
  border-right-width: 2px !important;
  min-height: 10px !important;
}
.css-vgb7rt {
  display: block !important;
  border-color: #bdb6cf73 !important;
  border-left-style: none;
  border-left-width: 0;

  margin-right: 7px !important;
  border-right-style: solid !important;
  border-right-width: 2px !important;
  min-height: 10px !important;
}

.MuiStepContent-root.css-14yr603-MuiStepContent-root {
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  border-left: none;

  // opacity: 1 !important;
  margin-right: 7px !important;
  padding-right: 20px !important;
  padding-left: 12px !important;
  border-right: 2px solid #bdb6cf73 !important;
}
.css-d0mviz {
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  border-left: none;

  // opacity: 1 !important;
  margin-right: 7px !important;
  padding-right: 20px !important;
  padding-left: 12px !important;
  border-right: 2px solid #bdb6cf73 !important;
}
