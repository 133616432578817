@import '../../../../markup/';

.purple-card-with-title {
  display: grid;
  grid-template-columns: max-content repeat(1, 1fr);
  background-color: #f1f2f9;
  border-radius: 20px;
  column-gap: 22px;
  padding-left: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  .card--title {
    width: max-content;
    min-width: 115px;
    height: max-content;
    color: white;
    padding: 0 15px;
    background-color: #686fc7;
    border-radius: 0px 19px;
    font-size: 20px;
    padding-bottom: 7px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f1f2f9 inset !important;
  }
  input {
    font-family: 'DIN Regular';
    padding-right: 0;
    direction: rtl;
    text-align: start;
    color: $Navy-Blue;
    text-align: start;
    height: 30px;
    background-color: transparent;
    width: 100%;
    border: none;
    font-weight: bold;
    box-sizing: border-box;
    transition: padding 0.3s;
  }
  textarea {
    font-family: 'DIN Regular';
    padding-right: 0;
    direction: rtl;
    height: 5em;
    font-size: 16px;
    color: $Navy-Blue;
    text-align: start;
    background-color: transparent;
    width: 100%;
    border: none;
    font-weight: bold;
    box-sizing: border-box;
    transition: padding 0.3s;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $Navy-Blue;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $Navy-Blue;
    }
  }
  .form-text-area:focus {
    border: none;
    overflow: auto;
  }
  .sub-filed {
    grid-row-start: 2;
    grid-column-start: 2;
    @media screen and (max-width: 812px) {
      grid-column-start: 1;
      padding-right: 13px;
    }
  }
  @media screen and (max-width: 812px) {
    grid-template-columns: unset;
  }
}
.edit-mode {
  input,
  textarea {
    background-color: #fff;
    border: 1px solid #50456698;
    padding-right: 8px;
  }
  textarea {
    height: 8em;
  }
  .form-text-area:focus {
    border: 1px solid #50456698;
    overflow: auto;
  }
}
