.searchInputStyle {
  font-family: DIN Regular;
  background-color: #12284f0d;
  width: 355px;
  height: 40px;
  font-weight: bold;
  padding-right: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.searchIconStyle {
  position: absolute;
  cursor: pointer;
  left: 18px;
  top: 12px;
  @media (max-width: 767px) {
    left: 0;
  }
}
.search-input {
  position: relative;
  grid-row-start: 2;
  width: fit-content;
  @media (max-width: 767px) {
    width: 100%;
  }
}
