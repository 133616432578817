@import '../../markup';

html {
  scroll-behavior: smooth;
}

.home-container {
  min-width: 80vw;
  min-height: 100vh;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  padding-top: 11%;
  //align-items: center;
  @media screen and (max-width: 812px) {
    width: 100%;
    position: relative;
  }
}

body {
  background-color: #f8f8f8;
}

.orders {
  background-color: white;
  max-width: 70vw;
  margin-bottom: 50px;
  min-height: 70vh;
  border-radius: 15px;
  margin-top: 11%;
  margin-right: 4%;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.orderes-header {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: row-reverse;
}
.header-left {
  width: 20%;
}
.nav-btn {
  text-decoration: none;
  cursor: pointer;
  font-family: 'DIN Med';
  font-weight: 100;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 53px;
  color: #513b71;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(to left, $dark-blue 50%, $light-blue 50%);
  background-position: left bottom;
  background-size: 201% 100%;
  transition: 0.5s;
  border-radius: 30px;
  border: 1px solid #becede;
  &:hover {
    .nav-btn {
      text-decoration: none;
      cursor: pointer;
      font-family: 'DIN Med';
      font-weight: 100;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 221px;
      height: 53px;
      color: #513b71;
      text-align: center;
      overflow: hidden;
      background: linear-gradient(to left, $dark-blue 50%, $light-blue 50%);
      background-position: left bottom;
      background-size: 201% 100%;
      transition: 0.5s;
      border-radius: 30px;
      border-style: none;
      &:hover {
        background-position: right bottom;
        color: white;
        // border: 1px solid white;
      }
      @media screen and (max-width: 812px) {
        width: 161px;
        font-size: 17px;
        height: 40px;
      }
    }

    background-position: right bottom;
    color: white;
    // border: 1px solid white;
  }
  @media screen and (max-width: 812px) {
    width: 161px;
    font-size: 17px;
    height: 40px;
  }
}

.header-right {
  width: 100%;
  height: 20%;
}

.orders-title {
  font-size: 32px;
  font-family: 'DIN Med';
  color: #513b71;
}
.orders-subtitle {
  font-size: 20px;
  font-family: 'DIN Regular';
  color: #513b71;
  margin-top: 8px;
}

.orders-content {
  background-color: #f7f6f8;
  min-height: 58vh;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  @media screen and (max-width: 812px) {
    margin-right: -13px;
    width: 110%;
  }
}

.content-header {
  min-height: 64px;
  max-width: 100%;
  background-color: #e8e6ee;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  font-family: 'DIN Med';
  color: #513b71;
  align-items: center;
  padding-right: 30px;
  padding-left: 100px;
  @media screen and (max-width: 812px) {
    padding-right: 15px;
    p:nth-child(1),
    p:nth-child(2),
    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5) {
      font-size: 16px;
      margin-left: 12px;
    }
  }
}

.content-list {
  max-width: 100%;
  min-height: 51vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: 812px) {
    margin-top: 32px;
  }
}

.no-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 24px;
  font-family: 'DIN Med';
  color: #513b71;
  @media screen and (max-width: 812px) {
    p:nth-child(2) {
      font-size: 20px;
    }
  }
}
.no-content-img {
  margin-top: 40px;
  margin-bottom: -20px;
  @media screen and (max-width: 812px) {
    margin-top: -11px;
    margin-bottom: 5px;
    width: 125px;
  }
}
.no-content-paragraph {
  font-size: 20px;
  font-family: 'DIN Regular';
  margin-top: 2px;
  @media screen and (max-width: 812px) {
    font-size: 19px;
  }
}

.small-btn {
  width: 177px;
  height: 40px;
  font-size: 20px;
  margin-top: 30px;
  @media screen and (max-width: 812px) {
    width: 161px;
    font-size: 17px;
    height: 40px;
  }
}

@media screen and (max-width: 565px) {
  .orderes-header {
    flex-direction: column;
  }
}
@media screen and (max-width: 812px) {
  .header-left {
    margin-top: 11rem;
  }
  .orders-subtitle {
    font-size: 19px;
  }
  .orders-title {
    font-size: 24px;
  }
  .orders {
    margin-right: -5%;
    padding: 42px;
  }
}
body > p:first-child {
  padding: 1vh 0 !important;
}

.login-btn {
  font-family: 'DIN Med';
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #513b71;
  text-align: center;
  background: linear-gradient(to left, #433b6c 50%, #becede 50%);
  background-position: left bottom;
  background-size: 201% 100%;
  transition: 0.5s;
  border-radius: 30px;
  border-style: none;
  padding: 5px 10px;
  line-height: 1.6;
  &.loggedin {
    padding: 5px 40px;
  }
  .arrow {
    margin-top: 3px;
    width: 28px;
    height: auto;
    margin-right: 10px;
  }
  &:hover {
    background-position: right bottom;
    color: white;
  }
}

.hide-btn {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
