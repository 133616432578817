@import '../variables/colors';

.input__error {
  width: 300px;
  color: $dark-red;
  display: block;
  font-family: $main-font-regular;
  position: absolute;
  @media screen and (max-width: 730px) {
    position: relative;
    width: auto;
  }
}

.input__error_form {
  color: $dark-red;
  display: block;
  font-size: 1rem;
  font-family: $main-font-regular;
  position: absolute;
  @media screen and (max-width: 730px) {
    position: relative;
  }
}
