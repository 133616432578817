@import '../../../../../markup';

.personal_info {
  &-form-line {
    border: 1px solid $mid-purple;
    opacity: 0.15;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      border: 1px solid $mid-purple;
    }
  }

  &-subtitle {
    color: #6c6c6c;
    font-family: $main-font-regular;
    margin-bottom: 2rem;
    font-size: 16px;

    @media screen and (max-width: 767px) {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  &-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 3rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }
  &-checkbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &-family {
    margin-left: 18.5rem;
    @media screen and (max-width: 1024px) {
      margin-left: 0px;
    }
  }
}

.inputText {
  margin-top: 0;
  padding: 0;
  text-indent: 8px;
  margin-bottom: 0;
  background-color: 'white';
  width: 220px;
  height: 36px;
  font-size: 17px;
  font-weight: 500;
  color: #584f70;
  font-family: 'DIN Regular';
  border: 1px solid #50456698;
  border-radius: $border-radius-small;
  // background-color: #5866450d;
  margin-top: 10px;
  outline: none;
  &:disabled {
    -webkit-text-fill-color: #584f70;
    background-color: #efefef;
  }

  @media screen and (max-width: 360px) {
    width: 240px;
    height: 32px;
  }

  @media only screen and (min-width: 361px) and (max-width: 427px) {
    width: 100%;
  }

  @media only screen and (min-width: 428px) and (max-width: 1023px) {
    width: 100%;
    height: 38px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
