@import '../../../markup';

.nav-line {
  // border: 2px solid white;
  background-color: white;
  opacity: 0.4;
  width: 90%;
  height: 0.5px;

  @media screen and (max-width: 767px) {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    // width: 100%;
    // border: 1px solid $mid-purple;
  }
}
.container-div {
  margin: auto 120px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: $phone-Large) {
    margin: auto 12px;
  }
}

.div-button {
  margin-right: 40px;
}
.col-goals {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  color: black;
  color: $dark-blue;
  font-family: $main-font-regular;
  @media screen and (max-width: $phone-Large) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 40px;
    gap: 60px;
  }
  @media screen and (min-width: $phone-Large) and (max-width: $laptop) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 50px;
  }
  @media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    gap: 50px;
  }
}
.layout {
  display: flex;
  gap: 30px;
}
.about-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0;
  @media screen and (max-width: 1200px) {
    margin: 0px 100px;
  }
  @media screen and (max-width: 990px) {
    margin: 0px 40px;
    justify-content: space-between;
  }
}

.logo {
  width: 120px;
  height: 94px;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    width: 100px;
    height: 57px;
    min-width: 101px;
    min-height: 40px;
  }
}

.about-nav > .logo {
  padding-left: 120px;
  @media screen and (max-width: 990px) {
    width: 130;
    height: 72px;
  }
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    width: 80px;
    height: 42px;
  }
}

.nav-item {
  color: white;
  font-size: 24px;
  letter-spacing: 0.5px;
  @media screen and (max-width: 780px) {
    display: none;
  }
}

.nav-btn,
.intro-btn {
  border-radius: 46px;
  width: 250;
  display: flex;
  justify-content: center;
  gap: 3px;
  padding-bottom: 4px;
  margin-bottom: -4px;
  min-width: 80px;
  cursor: pointer;
  font-family: 'DIN Regular';
  font-size: 21px;
  color: $dark-blue;
  text-align: center;
  font-weight: 600;
  overflow: hidden;
  background: linear-gradient(to left, $dark-blue 50%, $light-blue 50%);
  background-position: left bottom;
  background-size: 201% 100%;
  transition: 0.5s;
  border-radius: 30px;
  border-style: none;
  &:hover {
    background-position: right bottom;
    color: #ffffff;
    border: 1px solid white;
  }
}
.nav-btn-resize {
  @media screen and (max-width: 980px) {
    width: 200px;
  }
  @media screen and (max-width: 1000px) {
    width: 150px;
    // font-size: ;
  }
  @media screen and (max-width: 812px) {
    width: 120px;
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    width: 100px;
    font-size: 16px;
  }
}

.arrow {
  margin-top: 8px;
  width: 28px;
  height: 42px;
  font-size: 50px;
  font-weight: bold;
  align-items: center;
  margin-right: 10px;
  @media screen and (max-width: 990px) {
    margin-top: 6px;
    width: 20px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 4px;
    width: 14px;
  }
}

.nav-item {
  padding: 5px;
  position: relative;
  text-decoration: none;
  color: #ffffff !important;
  letter-spacing: 0.5px;
  margin: auto;
  font-family: 'DIN Regular';
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    color: #ffffff !important;
    background-color: #ffffff !important;
    height: 3px;
    width: 0;
    left: 0;
    bottom: 0px;
    transition: 0.3s;
  }
  &:hover {
    color: #ffffff !important;
    &:after {
      width: 100%;
    }
  }
}

.nav-bar {
  display: block;
  height: 2px;
  width: 30px;
  background-color: white;
  margin: 6px auto;
  transition: all 0.2s;
  position: relative;
}

.nav-small {
  display: none;
  @media screen and (max-width: 780px) {
    display: block;
    height: 30px;
    width: 30px;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    position: absolute;
    top: 20px;
    left: 30px;
  }
}

.first-bar {
  top: 0px;
  &--active {
    transform: rotate(45deg);
    top: 3px;
  }
}
.last-bar {
  top: 10px;
  &--active {
    top: -5px;
    transform: rotate(-45deg);
  }
}
.middle-bar {
  top: 5px;
  &--active {
    display: none;
  }
}

.menu-bg {
  background-image: url('../../../markup/images/topLayer.png');
}
