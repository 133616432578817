@import '../../../../markup/';
.cms-tabs {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f8f8f8;
  a {
    position: relative;
    color: #717e95;
    font-family: 'DIN Med';
    font-size: 20px;
    &.active {
      color: $Navy-Blue;
      &::after {
        content: '';
        position: absolute;
        bottom: -21px;
        right: 0;
        height: 2px;
        width: 100%;
        background-color: $Navy-Blue;
      }
    }
  }
}
