@import '../../../markup';

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f8f8f8;
}

.request-container {
  min-height: 100vh;
  margin-top: 110px;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  align-items: center;
  @media screen and (max-width: 812px) {
    width: 100%;
    position: relative;
  }
}

.request-header-link {
  display: flex;
  margin-bottom: 30px;
  align-self: flex-start;
  font-size: 18px;
  margin-right: 5vw;
}

.z-100 {
  z-index: 999;
}
.modal-btns {
  justify-content: flex-end;
  gap: 30px;
}
.modal-flex {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.modal-close-icon {
  position: absolute;
  left: 0;
}
.modal-textarea {
  border: 1px solid #1d203f80;
  border-radius: 25px;
  resize: none;
  padding: 15px;
  font-size: 18px;
  font-family: 'DIN Regular';
}

.pointer {
  cursor: pointer;
}

.header-message {
  background-color: #dde8e399;
  width: 83.5%;
  margin-bottom: 41px;
  border-radius: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 2%;
  @media screen and (max-width: 812px) {
    width: 86%;
  }
}
.header-message-home {
  background-color: #dde8e399;
  width: 75vw;
  border-radius: 20px;
  padding: 2%;
  margin-right: 1.2%;
  margin-bottom: 20px;
  @media screen and (max-width: 812px) {
    width: 86vw;
    margin-right: 5vw;
    margin-left: 5vw;
    padding: 2vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.header-message-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  color: #4c3b5e;
  font-family: DIN Med;
  font-size: 20px;
}
.header-message-sub {
  display: flex;
  flex-direction: row;
  color: #513b71;
  font-size: 17px;
}
.request-applicant-container {
  position: relative !important;
  .request-header-link {
    margin-right: 0;
}
  .orders-details {
    max-width: unset;
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-right: 10vw;
    padding-left: 10vw;
    margin-bottom: 50px;
    min-height: 70vh;
    padding-top: 60px;
    padding-bottom: 70px;
}
}