@import '../../../markup';

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f8f8f8;
}

.auditorList-container {
  min-width: 80vw;
  min-height: 100vh;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  align-items: center;
  padding-top: 11%;
  @media screen and (max-width: 812px) {
    width: 100%;
    position: relative;
  }
  .count-result-search {
    flex-direction: column;
    position: absolute;
    left: 25px;
    top: 5px;
    margin-right: 20px;
    padding-top: 11%;
    @media screen and (max-width: 812px) {
      width: 100%;
      position: relative;
    }

  }
}

