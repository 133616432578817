@import '../../../../../markup';

.div-AdditionalCertificates {
  background: #5045661a 0% 0% no-repeat padding-box;
  max-width: 96%;
  margin-right: 2%;
  margin-left: 2%;
  border-radius: 10px;
  padding-right: 5%;
  padding-left: 5%;
  @media screen and (max-width: 782px) {
    width: 100vw;
    height: 390px;
    border-radius: 1.4rem;
    padding: 20px;
  }
}

.mb-1 {
  margin-bottom: 1.9rem;
}

.header-AdditionalCertificates {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1.5rem;
  font-size: 17px;
  font-family: 'DIN Regular';
  color: #6c6c6c;
  margin-bottom: 0.5rem;
  padding-left: 13%;
}

.line-header-AdditionalCertificates2 {
  border: 1px solid $mid-purple;
  opacity: 0.15;
  margin-bottom: 3rem;
  margin-top: 3rem;
  width: 96%;
}

.sub-header-AdditionalCertificates {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 0px;
  margin-top: 0px;
}

.additionalField {
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  color: #584f70;
  border: 1px solid #50456698;
  border-radius: $border-radius-small;
  height: 24px;
  background-color: white;
  width: 27vw;
  outline: none;
  &:disabled {
    -webkit-text-fill-color: #584f70;
    background-color: #efefef;
  }
  @media screen and (max-width: 560px) {
    width: 80%;
    margin-bottom: 0.5rem;
  }
}

.inputAdditionalCertificates {
  padding-top: 0rem;
}

.div-upload-file {
  width: 7vw;
  max-width: 110px;
  height: 26px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #513b71;
  border-radius: 16px;
  align-items: center;
  opacity: 1;
  @media screen and (max-width: 782px) {
    width: 60px;
    height: 25px;
  }
}

.remove-row {
  cursor: pointer;
  max-width: 20rem;
}

.upload-prompt-AdditionalCertificates {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 0px;
  line-height: 10px;
  color: #504566;
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
  @media screen and (max-width: 1150px) {
    font-size: 11px;
  }
}

.remove-icon-Certificates {
  max-width: 0.8rem;
  padding-top: 0.3rem;
  padding-right: 0.7rem;
  cursor: pointer;
}

.input__error_cer {
  color: $dark-red;
  display: block;
  direction: ltr;
  // margin-right: -1rem;
  align-items: center;
  font-size: 0.8rem;
  font-family: $main-font-regular;
  position: absolute;
  @media screen and (max-width: 730px) {
    position: relative;
  }
}
.container {
  margin-top: 50px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.btn-AdditionalCertificates {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 42rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  color: #504566;
  height: 25px;
  font-family: $main-font-regular;
  background: #584f7026 0% 0% no-repeat padding-box;
  border: 1px dashed #513b71;
  border-radius: 20px;
  opacity: 1;
  align-items: center;
  @media screen and (max-width: 782px) {
    height: 50px;
  }
}
