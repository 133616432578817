@import '../../../markup/';
.otp-body {
  display: flex;
  justify-content: center;
}
/////////////////// OTP INPUT //////////
.otp {
  display: flex;
  margin-top: 18%;
  margin-bottom: 10%;
  align-items: center;
  flex-direction: column;
  .otp__input__error {
    margin-right: 8.5px;
    color: $dark-red;
    display: block;
    font-family: $main-font-regular;
  }
}
.otp-box {
  direction: ltr;
  display: flex;
  justify-content: center;
}
.otp-field {
  font-size: 26px;
  color: $mid-purple;
  text-align: center;
  width: 2.5rem;
  height: 3rem;
  background-color: #f6f5f7;
  border: 2px solid #afabb9;
  border-radius: 6px;
  opacity: 1;
  margin: 9px;
  outline: none !important;
  font-family: $main-font-medium;

  @media screen and (max-width: $phone-Small) {
    width: 1.6rem;
    height: 3rem;
    font-size: 20px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 2rem;
    height: 3rem;
    font-size: 20px;
  }
 
  &_error {
    background: #fff5f5;
    border: 2px solid #db8e8e;
    border-radius: 4px;
    width: 2.5rem;
    height: 3rem;
    margin: 9px;
    font-size: 26px;
    color: $mid-purple;
    text-align: center;
    font-family: $main-font-medium;
    @media screen and (max-width: $phone-Small) {
      width: 1.6rem;
      height: 3rem;
      font-size: 20px;
    }
    @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
      width: 2rem;
      height: 3rem;
      font-size: 20px;
    }
  }
}
/////////////////// ORP-TIMER ////////////
.resend-text {
  display: flex;
  align-items: center;
  border: none;
  background-color: #ffffff;
  color: #524468;
  text-decoration: underline;
  margin-top: 22px;
  font-family: $main-font-medium;
  font-size: 18px;
  cursor: pointer;
  margin-right: 1rem;
  border-bottom: 2px solid $mid-purple;
  padding: 0 0 4px;
  margin-top: 3rem;

  text-decoration-line: none;
  @media screen and (max-width: $phone-Large) {
    font-size: 16px;
    margin-top: 3rem;
  }

  span {
    display: none;
  }
}
.resend-text:disabled,
.resend-text[disabled] {
  color: #b3aeb9;
  text-decoration-line: none;
  border-bottom: none;
  margin-right: 1rem;
  padding: 0 0 0;
  cursor: default;
  span {
    display: block;
    margin-right: 0.7rem;
  }
}
.no-toster {
  display: flex;
  padding: 1.9rem 1rem;
  margin-bottom: 0.3rem;
}

.recaptch{
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
}