//  colors
$dark-purple: #1d203f;
$darker-purple: #1b223c;
$purple: #513b71;
$mid-purple: #504566;
$light-purple: #ada1c2;
$lighter-purple: #ebe6f0;
$light-yellow: #fdf5eb;
$yellow: #e9e1b4;
$dark-grey: #6c6c6c;
$mid-grey: #acacac;
$light-grey: #c7c7c7;
$light-green: #dde8e3;
$dark-red: #b41515;
$mid-red: #db8e8e;
$light-red: #fff5f5;
$highlight: rgba(0, 0, 0, 0);
$light-blue: #becede;
$dark-blue: #433b6c;
$select-border-color: #513b7198;
$white: #ffffff;
$Lavender-Gray: #686fc7;
$Navy-Blue: #12284f;
$light-purple-grey: #f7f6f8;
