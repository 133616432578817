@import '../../../markup';

.upload-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  height: 120px;
  background: #5045660d 0% 0% no-repeat padding-box;
  border: 2px dashed #4c3b5e;
  border-radius: 10px;
}

.upload-btn-dragged {
  border-style: solid;
}

.uploaded-file {
  align-items: center;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  height: 120px;
  border: 2px dashed #4c3b5e;
  border-radius: 10px;
  background-color: #50456626;
}

.upload-prompt {
  display: flex;
  align-items: flex-end;
  text-align: center;
  font-family: $main-font-regular;
  letter-spacing: 0px;
  color: #504566;
  opacity: 1;
  font-size: 18px;
  font-weight: bold;
  width: 80%;
  justify-content: center;
}

.upload-logo {
  width: 20%;
  max-width: 32px;
  min-width: 17px;
  height: 25px;
  margin-left: 12px;
}

.upload-text {
  text-align: center;
  font: normal normal 300 16px/18px DIN Next LT Arabic;
  letter-spacing: 0px;
  color: #6c6c6c;
  height: 5px;
  font-size: 16px;
  // margin-top: 0px;
  margin-bottom: 16px;
  margin-top: 3px;
}

.remove-icon {
  cursor: pointer;
}

.file-logo {
  margin-left: 10px;
}

.file-size {
  color: #6c6c6c;
  @media screen and (max-width: 990px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.file-name {
  color: #504566;
  @media screen and (max-width: 990px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 480px) {
  .upload-prompt {
    flex-direction: column;
    align-items: center;
    font-size: 15px;
  }
  .upload-text {
    font-size: 10px;
  }
}

.invisible-small {
  @media screen and (max-width: 780px) {
    display: none;
  }
}
