@import "../../../markup";
.form-select{
    color: #4c3b5e;
    padding: 1px 15px;
    height: 44px;
    width: 267px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left 1rem center;
    background-size: 1em;
    appearance: none;
    font-family: "DIN Regular";

}

select {
  -webkit-appearance: none;
  -moz-appearance:    none;
  -ms-appearance: none;
  appearance:         none;
  outline-color: $select-border-color ;
  font-family:inherit;
  
}

.form-label-c {
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
    color: #6c6c6c;
    font-size: 1.1rem;
    display: block;
    font-family: $main-font-regular;
  &_personal{
    @extend .form-label-c;
    font-size: 16px;
    margin-top: 0px;
    padding-top: 2rem;
  }
  }

  .form-group {
    // margin-bottom: 2.5rem;
    max-width: 472px;
    // padding-top: 2rem;
  }
  .personal-form-group {
    padding-top: 0rem;
  }

 
.form-input {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 16px;
    font-family: DIN;
    margin-top: 10px;
    width: 300px;
    height: 40px;
      font-family: "DIN Regular";

    border: 1px solid #50456698;
    border-radius: 8px;
    opacity: 1;
    text-align: right;
    display: block;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .mt-2{
    margin-top: 3rem;
  }
  .mb-2{
    margin-bottom: 1rem;
  }

@media screen and (max-width:480px) {
    .form-select{
        width: 227px;
        height: 30px;
        font-size: 15px;
    }
  }

  .error-text {
    color: #b41515;
  }
 