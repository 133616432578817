@import "../../../markup";

.reset-password-body {
  display: flex;
  justify-content: center;
}


.form_padding{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.input-padding{
  padding-bottom: 0.6rem;
}

.without_toster{
    display: flex;
    padding: 1.9rem 1rem;
    margin-bottom: 0.3rem;
    position: absolute;
}