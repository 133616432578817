// Din Next LT Arabic
@font-face {
  font-family: 'DIN Med';
  src: local('DIN Next LT Arabic Medium'), url('../../assets/fonts/ArbFONTS-DINNextLTArabic-Medium-2.ttf') format('truetype');
}

@font-face {
  font-family: 'DIN Bold';
  src: local('DIN Next LT Arabic Bold'), url('../../assets/fonts/ArbFONTS-DINNextLTArabic-Bold-2.ttf') format('truetype');
}

@font-face {
  font-family: 'DIN Regular';
  src: local('DIN Next LT Arabic Regular'), url('../../assets/fonts/ArbFONTS-DINNextLTArabic-Regular-2.ttf') format('truetype');
}
