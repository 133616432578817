@import '../../../markup/';
:root {
  --customWidth: 623px;
}

@media screen and (max-width: 812px) {
  :root {
    --customWidth: 163px;
  }
}
@media screen and (min-width: $laptop) and (max-width: $custom-laptop) {
  :root {
    --customWidth: 500px;
  }
}
@media screen and (min-width: 1035px) and (max-width: 1199px) {
  :root {
    --customWidth: 400px;
  }
}
@media screen and (min-width: 813px) and (max-width: 1036px) {
  :root {
    --customWidth: 255px;
  }
}
.orders-details-2 {
  background-color: white;
  width: 65vw;
  margin-bottom: 50px;
  height: 100%;
  padding: 40px;
  border-radius: 15px;
  margin-right: 4%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 812px) {
    padding: 42px;
    min-width: 75vw;
  }
}

.home-container-2 {
  min-width: 80vw;
  min-height: 100vh;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  // align-items: center;
  @media screen and (max-width: 812px) {
    width: 100%;
    position: relative;
    align-items: center;
  }
}
