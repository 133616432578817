@import '../../../markup';

.card__desc {
  align-self: center;
  font-family: $main-font-regular;
  color: $dark-grey;
  font-size: 1rem;
  margin-top: 1rem;

  .card__desc__link {
    color: $mid-purple;
    font-weight: bold;
  }
}
.tabs-container {
  position: relative;
  width: 400px;
  display: flex;
  overflow: hidden;
  border-radius: 1.5rem 1.5rem 0 0;
  .btn {
    border-radius: 0;
    font-size: 1.1rem;
    color: $mid-purple;
    &.active {
      background-color: $white;
    }
  }

  @media screen and (max-width: $phone-Small) {
    width: 336px;
  }
  @media screen and (min-width: $phone-Small) and (max-width: $phone-Large) {
    width: 400px;
  }

  
}
.applicant-card.card .card__header__subtitle {
  font-family: 'DIN MED';
  font-size: 1rem;
}
