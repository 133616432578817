.member-card-name {
  margin-bottom: 40px;
  .member--title {
    color: #6c6c6c;
  }
  .member--name {
    color: #12284f;
    font-weight: bold;
  }
  .member--delete {
    all: unset;
    cursor: pointer;
    color: #b41515;
    font-size: 14px;
  }
  .member--edit {
    all: unset;
    cursor: pointer;
    color: #686fc7;
    font-size: 14px;
  }
  .member--position {
    font-size: 14px;
    color: #333;
  }
}
.ant-popconfirm-message-title,
.ant-btn {
  font-family: 'DIN Regular';
}
.ant-btn-primary {
  background-color: #686fc7 !important;
}
.input-style-show {
  background-color: #fff !important;
  border: 1px solid #50456698 !important;
  padding-right: 8px !important;
}
