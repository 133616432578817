@import "../../../markup";

.btn {
  text-decoration: none;
  font-family: $main-font-medium;
  direction: ltr !important;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 13rem;
  height: 3rem;
  text-align: center;
  font-weight: 600;
  overflow: hidden;
  border-radius: 46px;
  -webkit-background-size: 201% 100%;
  -webkit-appearance: none;
  background-position: left bottom;
  background-size: 201% 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: none;
  padding-bottom: 0.4rem;

  .arrow {
    margin-top: 12px;
    width: 28px;
    height: 42px;
    font-size: 50px;
    font-weight: bold;
    align-items: center;
    margin-right: 10px;
  }

  &:hover {
    background-position: right bottom;
    color: #ffffff;
  }

  &:disabled {
    background: #acacac;
    color: #ffffff;
    border-color: #acacac;
    border: 0px;
  }
}

.no-underline {
  text-decoration: none;
}

.wide_button {
  width: 20rem;
  height: 2.7rem;
}

.btn--primary {
  color: $dark-blue;
  background-image: linear-gradient(to left,
      $dark-blue 50%,
      $light-blue 50%);
  background-image: -webkit-gradient(linear,
      from($dark-blue 50%),
      to($light-blue 50%));
  background-image: -webkit-linear-gradient(left,
      $dark-blue 50%,
      $light-blue 50%);
  background-image: -moz-linear-gradient(left,
      $dark-blue 50%,
      $light-blue 50%);
  background-image: -o-linear-gradient(left,
      $dark-blue 50%,
      $light-blue 50%);
}

.btn--secondary {
  color: #ffffff;
  background-image: linear-gradient(to left, #4e5284 50%, #797ec4 50%);
  background-image: -webkit-gradient(linear,
      from(#4e5284 50%),
      to(#797ec4 50%));
  background-image: -webkit-linear-gradient(left, #4e5284 50%, #797ec4 50%);
  background-image: -moz-linear-gradient(left, #4e5284 50%, #797ec4 50%);
  background-image: -o-linear-gradient(left, #4e5284 50%, #797ec4 50%);
}