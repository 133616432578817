.ml-20 {
  margin-left: 20px !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-top {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
.rounded-bottom {
  border-radius: 0 0 1.5rem 1.5rem !important;
}
.bg-white {
  background-color: $white !important;
}
.w-20rem {
  width: 20rem !important;
}
.bg-grey {
  background-color: #ccc !important;
}
.btn-sm {
  font-size: 16px !important;
  height: 30px !important;
  width: auto !important;
}
.bg-light-purple-grey {
  background-color: $light-purple-grey;
}
.text-purple {
  color: $purple !important;
}
.text-left {
  text-align: left !important;
}
.light-hr {
  border-color: #f8f8f8 !important;
  border-style: solid !important;
}
.visibility-hidden {
  visibility: hidden !important;
}
.font-regular {
  font-family: 'DIN Regular' !important;
  font-weight: 400 !important;
}
.font-bold {
  font-family: 'DIN Bold' !important;
}
.w-100 {
  width: 100% !important;
}
.h-30px {
  height: 30px !important;
}
.position-relative {
  position: relative !important;
}
.position-unset {
  position: unset !important;
}
